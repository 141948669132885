import React from "react";

import {
    Button,
    ButtonGroup,
    Input,
    Spinner,
    InputGroup,
    InputGroupAddon,
    Table,
    UncontrolledCollapse,
    Alert
} from 'reactstrap';
import { UserContext } from '../../../providers/UserProvider'
import { UserApi } from '../../../providers/UserProvider'
import { auth, firestore } from "../../../firebase";
import SweetAlert from "react-bootstrap-sweetalert";
import './userStrategies.css'
import NotificationAlert from 'react-notification-alert';
import EditStrategy from '../editStrategy/editStategy'
import ApiService from '../../../components/ApiService/index'
import styled from 'styled-components';
import TakeProfits from "../takeProfits/takeProfits";
import ChooseStrategy from "../chooseStrategy/chooseStrategy";

const Asd = styled(Table)`
    font-size: ${props => props.width}% !important;
`;

const Tr = styled.tr`
    height: ${props => props.rowHeight}px !important;
`;

const Th = styled.th`
    font-size: ${props => props.theme.width} !important;
`;

const chooseStatusIcon = ({ value }) => {
    switch (value) {
        case 'start':
            return <span className="fa fa-play text-center" />
        case 'stop':
            return <span className="fa fa-stop text-center" />
        case 'softStop':
            return <span className="fa fa-pause text-center" />
        case 'hardStop':
            return <span className="fa fa-adjust text-center" />
        case '':
            return <span className="fa fa-power-off text-center text-danger" />
        default:
            return value
    }
}

const chooseSideIcon = (value) => {
    switch (value) {
        case 'LONG':
            return <span className="fa fa-arrow-up" style={{ color: 'green' }} />
        case 'SHORT':
            return <span className="fa fa-arrow-down" style={{ color: 'red' }} />
        case 'BOTH':
            return <span className="fa fa-exchange" style={{ transform: 'rotate(90deg)', color: '#0062cc' }}></span>
        default:
            return value
    }
}

class UserStrategies extends React.Component {
    static contextType = UserContext
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { key: 'grid-orders', name: 'Grid Orders', visible: true },
                { key: 'order-type', name: 'Order Type', visible: true },
                { key: 'step-factor', name: 'Step Factor', visible: true },
                { key: 'order-size', name: 'Order Size', visible: true },
                { key: 'order-factor', name: 'Order Factor', visible: true },
                { key: 'take-step', name: 'Take Step', visible: true },
                { key: 'stop-loss-offset', name: 'Stoploss Offset', visible: true },
                { key: 'note', name: 'Note', visible: true },
            ],
            rows: [],
            visibleRows: null,
            selectedIds: [],
            user: {},
            processedStrategy: null,
            showImportStrategyModalState: false,
            wrongImportedFormat: '',
            columnsDropdownOpen: false,
            tagsDropdownOpen: false,
            userTags: [],
            showTags: [],
            toggleShowCheckbox: false,
            selectedRows: [],
            rerender: false,
            currentAccount: 0,
            currentModal: '',

            fontSize: 80,
            rowHeight: 39,

            selectedAllCheckbox: false,

            mode: '',

            strategyChanges: {},

            options: {
                alignLabels: true,
                timeScale: {
                    rightOffset: 12,
                    barSpacing: 3,
                    fixLeftEdge: true,
                    lockVisibleTimeRangeOnResize: true,
                    rightBarStaysOnScroll: true,
                    borderVisible: false,
                    borderColor: "#fff000",
                    visible: true,
                    timeVisible: true,
                    secondsVisible: false
                }
            },
            candlestickSeries: [{
                data: [
                    { time: '2021-09-01', open: 180.34, high: 180.99, low: 178.57, close: 179.85 },
                    { time: '2021-09-02', open: 180.82, high: 181.40, low: 177.56, close: 178.75 },
                    { time: '2021-09-03', open: 175.77, high: 179.49, low: 175.44, close: 178.53 },
                    { time: '2021-09-04', open: 178.58, high: 182.37, low: 176.31, close: 176.97 },
                    { time: '2021-09-05', open: 177.52, high: 180.50, low: 176.83, close: 179.07 },
                    { time: '2021-09-06', open: 176.88, high: 177.34, low: 170.91, close: 172.23 },
                    { time: '2021-09-07', open: 173.74, high: 175.99, low: 170.95, close: 173.20 },
                    { time: '2021-09-09', open: 173.16, high: 176.43, low: 172.64, close: 176.24 },
                    { time: '2021-09-10', open: 177.98, high: 178.85, low: 175.59, close: 175.88 },
                ],
                markers: [
                    {
                        time: '2021-09-02',
                        position: 'aboveBar',
                        color: 'red',
                        shape: 'circle',
                    },
                    {
                        time: '2021-09-08',
                        position: 'belowBar',
                        color: 'green',
                        shape: 'circle',
                    }
                ]
            }],

            columnsOrders: {},
            loaders: {},

            singleRisk: null,

            pendingAction: null,
            confirmBulkActionButton: true
        }
        this.hiddenTags = []
        this.apiService = new ApiService()
    }

    notification = (obj) => {
        this.refs.notifyRef.notificationAlert({
            place: obj.position ? obj.position : 'tc',
            message: (
                <div>
                    <div>
                        <b>{obj.text}</b>
                    </div>
                </div>
            ),
            type: obj.type,
            autoDismiss: obj.autoDismiss ? obj.autoDismiss : 5,
        });
    }

    risk = async () => {
        let loaders = this.state.loaders
        loaders['riskCalculator'] = true
        this.setState({
            loaders
        })
        const ids = []
        this.state.visibleRows.forEach(strategy => {
            if (strategy.c.status)
                ids.push(strategy.id)
        })
        console.log('ids to change', ids)

        const headers = { headers: { 'authorization': `Bearer ${this.state.user.Aa}` } }

        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
        if (storage.account) {
            const risk = await this.apiService.risk(Buffer.from(storage.account, 'base64'), ids, headers)
            console.log('res', risk)
            if (ids.length === 1) {
                this.setState({
                    singleRisk: risk
                })
            } else {
                this.setState({
                    currentModal: 'risk',
                    risk
                })
            }
        }
        loaders['riskCalculator'] = false
        this.setState({
            loaders
        })
    }

    checkBoxFormatter = ({ value }) => {
        const status = this.state.visibleRows[value.id] ? this.state.visibleRows[value.id].c.status : false
        return <input
            type="checkbox"
            id={value.id}
            checked={status}
            onChange={(e) => {
                let tmp = this.state.visibleRows
                tmp[e.target.getAttribute('id')].c.status = e.target.checked
                tmp.push({})
                this.setState({
                    visibleRows: tmp
                }, () => {
                    tmp.pop()
                    this.setState({
                        visibleRows: tmp
                    })
                })
            }}
        />
    }


    toggleModal = (modal) => {
        this.setState({
            currentModal: modal
        })
    }

    componentDidMount() {
        const user = this.context
        // get user tags saved on localstorage
        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}

        this.setState({
            fontSize: storage.tableSize ? storage.tableSize : 80
        })

        auth.onAuthStateChanged(userAuth => {
            console.log(userAuth)
            this.setState({ user: userAuth });

            if (storage.account) {
                this.setState({
                    currentAccount: Buffer.from(storage.account, 'base64')
                })
                // polling from firestore for user strategies
                firestore
                    .collection('strategies')
                    .where("botId", '==', atob(storage.account))
                    .where('userId', '==', userAuth.uid)
                    .onSnapshot((snapshot) => {
                        let tmpArray = []

                        if (snapshot.size === 0) {
                            this.setState({
                                rows: [],
                                visibleRows: []
                            })
                        } else {
                            snapshot.forEach((doc) => {
                                const newStrategy = { ...doc.data(), id: doc.id }
                                // if rows are already setted, this an update for firestore status
                                if (this.state.rows) {
                                    // look for the changed strategy
                                    const prevStrategy = this.state.rows.filter(strategy => strategy.id === newStrategy.id)[0]
                                    // console.log(`${newStrategy.status} !== ${prevStrategy.status}`)
                                    // check if bot status changed, if true show notification pop-up
                                    if (prevStrategy && newStrategy.status !== prevStrategy.status) {
                                        this.notification({
                                            type: 'warning',
                                            text: `Bot ${newStrategy.symbol} changed status`
                                        })
                                    }
                                }
                                // push into future rows state
                                tmpArray.push(newStrategy)
                            });
                            // add extra prop for checkbox column selection
                            tmpArray = tmpArray
                                .map((strategy, index) => ({
                                    ...strategy,
                                    c: {
                                        id: index,
                                        status: false
                                    }
                                }))
                                .sort((a, b) => {
                                    if (a.symbol > b.symbol) return 1;
                                    if (a.symbol < b.symbol) return -1;
                                    return 0;
                                });
                            this.setState({
                                rows: tmpArray,
                                visibleRows: tmpArray,
                                selectedRows: Array(tmpArray.length).fill(false),
                                showTags: Array(storage.tags).fill(false),
                            })
                        }
                    })
            } else {
                this.setState({
                    currentAccount: -1
                })
            }
            if (userAuth === null) {
                const params = new URLSearchParams(this.props.location.search);
                const ref = params.get('ref');
                console.log('ref', ref)
                window.location.href = ref ? `/sign-in?ref=${ref}` : "/sign-in"
            }
        });
    }

    toggleAllCheckbox = (checked) => {
        const visibleRows = this.state.visibleRows.map(i => { return i })
        visibleRows.forEach(row => {
            row.c.status = checked
        })
        visibleRows.push({})
        this.setState({
            visibleRows
        }, () => {
            visibleRows.pop()
            this.setState({
                visibleRows
            })
        })
    }

    processImportedStrategy = (e) => {
        try {
            console.log(e.target.value)
            const obj = JSON.parse(e.target.value)
            let processedStrategy = ''
            Object.keys(obj).forEach(i => {
                if (i !== 'id' && i !== 'width')
                    processedStrategy = processedStrategy + `<div class="col-6 text-right font-weight-bold">${i}:</div>` + " " + `<div class="col-6 text-left font-weight-normal">${obj[i]}</div>`
            })
            this.setState({
                processedStrategy
            })
        } catch (e) {
            console.log('non oggetto')
            this.setState({
                wrongImportedFormat: 'border border-danger'
            })
        }
    }

    showImportStrategyModal = () => {
        this.setState({
            showImportStrategyModalState: true
        })
    }

    importStrategy = () => {
        console.log('import strategy..')
        this.setState({
            processedStrategy: null,
            wrongImportedFormat: false
        })
    }

    hideAlert = () => {
        this.setState({
            alert: null,
            showImportStrategyModalState: false,
            processedStrategy: null,
            wrongImportedFormat: false,
            currentModal: '',
            pendingAction: null
        })
    }

    filterStrategies(param) {
        switch (param) {
            case 'all':
                this.setState({
                    visibleRows: this.state.rows
                })
                break;
            case 'default':
                this.setState({
                    visibleRows: this.state.rows.filter(strategy => strategy.typeGrid === 'standard')
                })
                break;
            case 'custom':
                this.setState({
                    visibleRows: this.state.rows.filter(strategy => strategy.typeGrid === 'custom')
                })
                break;
            default:
                console.log('filter not implemented yet')
                break;
        }
    }

    filterOnInputText(text) {
        text = text.toUpperCase()
        if (text === "") {
            this.resetTextFilter()
        } else {
            const result = this.state.visibleRows.filter(strategy => strategy.symbol.toUpperCase().indexOf(text) > -1 || strategy.note.toUpperCase().indexOf(text) > -1)
            this.setState({
                visibleRows: result
            })
        }
    }

    resetTextFilter() {
        this.setState({
            visibleRows: this.state.rows
        })
    }

    copyStrategyOnClipboard = (strategy) => {
        navigator.clipboard.writeText(JSON.stringify(strategy))
        this.setState({
            currentModal: 'copied'
        })
    }

    selectAllRows = (e) => {
        console.log(e.target.checked)
        const rows = this.state.visibleRows
        rows.forEach(strat => {
            if (strat.c.status !== e.target.checked) {
                strat.c.status = e.target.checked
            }
        })
        this.setState({
            visibleRows: rows
        })
    }

    updateBulkEdit = (value) => {
        let tmpValue = null
        let strategies = this.state.visibleRows;
        // update rows values before firestore update
        for (let i = 0; i < strategies.length; i++) {
            if (strategies[i].c.status) {
                // if strategy[i] is selected for editing cast to float several columns
                if (
                    this.state.editableField === 'orderSize' ||
                    this.state.editableField === 'gridStep' ||
                    this.state.editableField === 'gridOrders' ||
                    this.state.editableField === 'stepFactor' ||
                    this.state.editableField === 'orderFactor' ||
                    this.state.editableField === 'takeStep' ||
                    this.state.editableField === 'gridStep'
                ) {
                    tmpValue = parseFloat(value)
                } else {
                    tmpValue = value
                }
                // and then change values
                strategies[i][this.state.editableField] = tmpValue
            }
        }

        // set firestore update value
        let tmpStrategyChanges = this.state.strategyChanges
        tmpStrategyChanges[this.state.editableField] = tmpValue
        // update react state
        this.setState({
            visibleRows: strategies,
            editableValue: tmpValue,
            tempStrategyChanges: tmpStrategyChanges
        })
        console.log('finito')
    }

    editableField = (field) => {
        const strategySelected = this.state.visibleRows.filter(strategy => strategy.c.status)[0]
        if (strategySelected) {
            this.setState({
                editableField: this.state.editableField === field ? '' : field
            })
            if (this.state.editableValue && this.state.editableField === field) {
                this.syncBulkChanges(this.state.editableValue)
            }
        } else {
            this.sortStrategies(field)
        }
    }

    syncBulkChanges = async (value) => {
        let a = this.state.visibleRows;
        let lastStrategy = null
        a.map(strategy => {
            if (strategy.c.status)
                lastStrategy = strategy.id
        })

        for (let i = 0; i < a.length; i++) {
            if (a[i].c.status) {
                try {
                    const res = await firestore.collection("strategies").doc(a[i].id).set(
                        this.state.strategyChanges,
                        { merge: true }
                    )
                    await this.apiService.operation(
                        'reload',
                        [a[i].id],
                        {
                            headers: {
                                'authorization': `Bearer ${this.state.user.Aa}`
                            }
                        }
                    )
                } catch (e) {
                    alert(e)
                }
            }
        }
        this.notification({
            type: 'success',
            text: `Updated`
        })
        this.setState({
            strategyChanges: {}
        })
        console.log('finito')
    }

    bulkActionStrategies = async (action) => {
        this.setState({
            pendingAction: action,
            confirmBulkActionButton: true
        })
    }

    confirmBulkAction = async() => {
        this.setState({
            confirmBulkActionButton: false
        })
        const ids = []
        this.state.visibleRows.forEach(strategy => {
            if (strategy.c.status)
                ids.push(strategy.id)
        })
        console.log('ids to change', ids)
        const headers = { headers: { 'authorization': `Bearer ${this.state.user.Aa}` } }
        let res = null
        console.log(this.state.pendingAction)
        switch (this.state.pendingAction) {
            case 'start':
                res = await this.apiService.operation(
                    'start',
                    ids,
                    headers
                )
                console.log(res);
                if (res && res.code && res.code === 400) {
                    this.notification({
                        type: 'danger',
                        text: res.message,
                        position: 'bc',
                        autoDismiss: 10
                    })
                }
                break;
            case 'stop':
                res = await this.apiService.operation(
                    'stop',
                    ids,
                    headers
                )
                console.log(res);
                break;
            case 'soft-stop':
                res = await this.apiService.operation(
                    'softStop',
                    ids,
                    headers
                )
                console.log(res);
                break;
            case 'hard-stop':
                res = await this.apiService.operation(
                    'hardStop',
                    ids,
                    headers
                )
                console.log(res);
                break;
            case 'restart':
                res = await this.apiService.operation(
                    'restart',
                    ids,
                    headers
                )
                console.log(res);
                break;
            default:
                console.log('nothing')
                break;
        }
        this.setState({
            pendingAction: null
        })
    }

    takeProfits = async () => {
        console.log('click')
        const headers = { headers: { 'authorization': `Bearer ${this.state.user.Aa}` } }
        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
        if (storage.account) {
            const profits = await this.apiService.takeProfits(Buffer.from(storage.account, 'base64'), headers)
            console.log('res', profits)
            this.setState({
                currentModal: 'takeProfits',
                profits
            })
        }
    }

    positions = async () => {
        const res = await this.apiService.positions()
    }

    toggle = (id) => {
        let oldArray = this.state.visibleRows
        oldArray.map(strategy => {
            if (strategy.id === id) {
                strategy.c.status = !strategy.c.status
            }
        })
        this.setState({
            visibleRows: oldArray
        })
    }

    somethingSelected = () => {
        return this.state.visibleRows?.filter(s => s.c.status)[0] ? false : true
    }

    deleteStrategy = async () => {
        try {
            this.setState({
                currentModal: ''
            })
            const res = await firestore.doc(`strategies/${this.state.currentEditingStrategy.id}`).delete()
            console.log('res', res)
            this.notification({
                type: 'success',
                text: 'Your  strategy has been deleted successfully!'
            })
        } catch (e) {
            console.log(e)
            this.setState({
                currentModal: ''
            })
            this.notification({
                type: 'danger',
                text: 'Couldn\'t delete this strategy!'
            })
        }
    }

    /**
     * asset
     * @param {string} quote 
     */
    getFixedPositions = (quote) => {
        switch (quote) {
            case 'USDT':
                return 4;
            case 'BUSD':
                return 2;
            case 'BNB':
                return 8;
            default:
                return 8;
        }
    }

    showTagsModal = () => {
        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
        let userTags = storage.tags ? storage.tags : []
        this.setState({
            userTags,

        })
        this.toggleModal('tagsModal')
    }

    sortStrategies = (param) => {
        const prevOrders = this.state.columnsOrders
        let orderedStrategies = this.state.visibleRows
        if (prevOrders[param] && prevOrders[param] === 1) {
            orderedStrategies = this.state.visibleRows.sort((a, b) => {
                if (a[param] < b[param]) return 1;
                if (a[param] > b[param]) return -1;
                return 0;
            });
            prevOrders[param] = 0
        } else {
            orderedStrategies = this.state.visibleRows.sort((a, b) => {
                if (a[param] > b[param]) return 1;
                if (a[param] < b[param]) return -1;
                return 0;
            });
            prevOrders[param] = 1
        }

        this.setState({
            visibleRows: orderedStrategies,
            columnsOrders: prevOrders
        })
    }

    render() {
        return <div className="content">
            {
                this.state.user ?
                    <div>
                        <div>
                            {/*<!-- ONLY ON DESKTOP --> */}
                            <div className="d-none d-md-none d-sm-none d-lg-block d-xl-block mx-auto">
                                <div style={{ float: 'left' }}>
                                    <button type="button" className="btn btn-sm btn-success toggler"> <i className="fa fa-sliders"></i></button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-success"
                                        onClick={() => this.setState({
                                            currentModal: 'newStrategy'
                                        })}
                                    >
                                        <i className="fa fa-plus"></i> New
                                    </button>
                                </div>
                                <div className="text-center">
                                    {/*<Button className="btn-success btn-sm" onClick={() => this.showImportStrategyModal()}><i className="fa fa-upload"></i> IMPORT</Button>{' '}*/}
                                    <Button
                                        className="btn-primary btn-sm"
                                        disabled={this.somethingSelected()}
                                        onClick={() => this.bulkActionStrategies('start')}
                                    >
                                        <i className="fa fa-play"></i> START
                                    </Button>
                                    <Button
                                        className="btn-primary btn-sm"
                                        disabled={this.somethingSelected()}
                                        onClick={() => this.bulkActionStrategies('stop')}
                                    >
                                        <i className="fa fa-stop"></i> STOP
                                    </Button>
                                    <Button
                                        className="btn-primary btn-sm"
                                        disabled={this.somethingSelected()}
                                        onClick={() => this.bulkActionStrategies('soft-stop')}
                                    >
                                        <i className="fa fa-pause"></i> SOFT STOP
                                    </Button>
                                    <Button
                                        className="btn-primary btn-sm"
                                        disabled={this.somethingSelected()}
                                        onClick={() => this.bulkActionStrategies('hard-stop')}
                                    >
                                        <i className="fa fa-adjust"></i> HARD STOP
                                    </Button>
                                    <Button
                                        className="btn-primary btn-sm"
                                        disabled={this.somethingSelected()}
                                        onClick={() => this.bulkActionStrategies('restart')}
                                    >
                                        <i className="fa fa-repeat"></i> RESTART
                                    </Button>
                                    <Button
                                        className="btn-danger btn-sm"
                                        disabled={this.somethingSelected()}
                                        onClick={() => this.risk()}
                                    >
                                        {this.state.loaders['riskCalculator'] === true ? <Spinner size="sm" color="warning" /> : <span><i className="fa fa-exclamation-circle"></i> RISK</span>}
                                    </Button>
                                    {/*<Button
                                            className="btn-danger btn-sm"
                                            onClick={() => this.positions()}
                                        >
                                            <i className="fa fa-list-ul"></i> POSITIONS
                                        </Button>*/}
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-2 mb-2">
                                        <div className="form-grup">
                                            <InputGroup size="sm">
                                                <InputGroupAddon addonType="prepend">
                                                    <Button className="m-0" onClick={() => this.resetTextFilter()}>reset</Button>
                                                </InputGroupAddon>
                                                <Input placeholder="Search by pair and note" onChange={(e) => this.filterOnInputText(e.target.value)} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ONLY ON MOBILE */}
                            <div className="d-lg-none d-xl-none d-sm-block d-xs-block text-center">
                                <button type="button" className="btn btn-sm btn-success toggler"> <i className="fa fa-sliders"></i></button>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={() => {
                                        console.log('clickk')
                                        this.setState({
                                            currentModal: 'newStrategy'
                                        })
                                    }}
                                >
                                    <i className="fa fa-plus"></i> New
                                </button>
                                <div className="row text-center">
                                    <div className="mx-auto">
                                        {/*<Button className="btn-primary btn-sm" onClick={() => this.showImportStrategyModal()}><i className="fa fa-upload"></i></Button>{' '}*/}
                                        <Button className="btn-primary btn-sm" disabled={this.somethingSelected()} onClick={() => this.bulkActionStrategies('start')}><i className="fa fa-play"></i>START</Button>{' '}
                                        <Button className="btn-primary btn-sm" disabled={this.somethingSelected()} onClick={() => this.bulkActionStrategies('soft-stop')}><i className="fa fa-pause"></i>SOFT STOP</Button>
                                        <Button className="btn-primary btn-sm" disabled={this.somethingSelected()} onClick={() => this.bulkActionStrategies('stop')}><i className="fa fa-stop"></i>STOP</Button>{' '}
                                        <Button className="btn-primary btn-sm" disabled={this.somethingSelected()} onClick={() => this.bulkActionStrategies('hard-stop')}><i className="fa fa-exclamation-triangle"></i>HARD STOP</Button>
                                        <Button className="btn-primary btn-sm" disabled={this.somethingSelected()} onClick={() => this.bulkActionStrategies('restart')}><i className="fa fa-repeat"></i>RESTART</Button>
                                        <Button
                                            className="btn-danger btn-sm"
                                            disabled={this.somethingSelected()}
                                            onClick={() => this.risk()}
                                        >
                                            {this.state.loaders['riskCalculator'] === true ? <Spinner size="sm" color="primary" /> : <i className="fa fa-exclamation-circle"></i>}
                                        </Button>
                                        {/*<Button className="btn-danger btn-sm" onClick={() => this.positions()}><i className="fa fa-list-ul"></i></Button>*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-2 mb-2">
                                        <div className="form-grup">
                                            <InputGroup size="sm">
                                                <InputGroupAddon addonType="prepend">
                                                    <Button className="m-0" onClick={() => this.resetTextFilter()}>reset</Button>
                                                </InputGroupAddon>
                                                <Input placeholder="Search by pair and note" onChange={(e) => this.filterOnInputText(e.target.value)} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.singleRisk ?
                                    <Table responsive bordered striped className="risk-calculator mx-auto">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Symbol</th>
                                                <th className="text-center">Market Price</th>
                                                <th className="text-center">First<br />Order<br />Size</th>
                                                <th colSpan={2} className="text-center">Loss (stop loss)</th>
                                                <th colSpan={3} className="text-center">Grid Exposure</th>
                                                <th colSpan={2}></th>
                                                <th colSpan={6} className="text-center">Last Grid Risk</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th className="text-center">Grid Value</th>
                                                <th className="text-center">Margin Value</th>
                                                <th className="text-center">Margin %</th>
                                                <th colSpan={2} className="text-center" style={{ backgroundColor: '#fdc44c7a' }}>Loss</th>
                                                <th className="text-center">Last<br />Order<br />Price</th>
                                                <th className="text-center">Last order price <br />to market price</th>
                                                <th className="text-center">Average<br />Price</th>
                                                <th className="text-center">Required %<br />change to profit</th>
                                                <th className="text-center">Average price<br />to market price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.singleRisk?.risk.map((risk, key) => {
                                                    return <tr key={key}>
                                                        <td>{risk.symbol}<br />({risk.positionSide} {risk.leverage}x)</td>
                                                        <td>{risk.marketPrice.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td>{risk.sizeFirstStep.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td>{risk.lossStopLossValue.toFixed(2)}</td>
                                                        <td>{risk.lossStopLossPerc.toFixed(2)}%</td>
                                                        <td>{risk.gridValue.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td>{risk.marginUsedValue.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td>{risk.marginUsedPercentage.toFixed(2)}%</td>
                                                        <td style={{ backgroundColor: '#fdc44c7a' }}>{risk.gridLastLossValue.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td style={{ backgroundColor: '#fdc44c7a' }}>{risk.gridLastLossPerc.toFixed(2)}%</td>
                                                        <td>{risk.gridLastPrice.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td>{risk.gridLastPriceVsMarketPricePerc.toFixed(2)}%</td>
                                                        <td>{risk.gridLastEntryPrice.toFixed(4)} {risk.quoteAsset}</td>
                                                        <td>{risk.gridLastEntryVsGridLastPricePerc.toFixed(2)}%</td>
                                                        <td>{risk.gridLastEntryVsMarketPricePerc.toFixed(2)}%</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    : <p></p>
                            }

                            <UncontrolledCollapse toggler=".toggler" defaultopenprop="false" className="mb-2">
                                <div className="d-flex flex-wrap justify-content-between">
                                    <div>
                                        { /* ZOOM OUT */}
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                                this.setState({
                                                    fontSize: this.state.fontSize - 10
                                                })

                                                let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
                                                storage.tableSize = this.state.fontSize - 10
                                                localStorage.setItem('preferences', JSON.stringify(storage));
                                            }}
                                        ><i className="fa fa-search-minus"></i></button>
                                        { /* ZOOM IN */}
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                                this.setState({
                                                    fontSize: this.state.fontSize + 10
                                                })

                                                let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
                                                storage.tableSize = this.state.fontSize + 10
                                                localStorage.setItem('preferences', JSON.stringify(storage));
                                            }}
                                        ><i className="fa fa-search-plus"></i></button>

                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                                if (document.getElementsByClassName('sidebar')[0].style.display !== 'none') {
                                                    document.getElementsByClassName('sidebar')[0].style.display = 'none';
                                                    document.getElementsByClassName('main-panel')[0].style.width = '100%';
                                                } else {
                                                    document.getElementsByClassName('sidebar')[0].style.display = '';
                                                    document.getElementsByClassName('main-panel')[0].style.width = 'calc(100% - 80px)';
                                                }
                                            }}
                                        >
                                            <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                        </button>


                                    </div>
                                    <div>

                                        <ButtonGroup>
                                            <Button className="btn-success btn-sm" onClick={() => this.filterStrategies('all')}>All</Button>
                                            <Button className="btn-success btn-sm" onClick={() => this.filterStrategies('default')}>Standard</Button>
                                            <Button className="btn-success btn-sm" onClick={() => this.filterStrategies('custom')}>Custom</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div>
                                        <ButtonGroup>
                                            <Button caret="true" color="success" size="sm" onClick={() => this.toggleModal('columnsModal')}>
                                                COLUMNS
                                            </Button>
                                            <Button caret="true" color="success" size="sm" onClick={() => this.showTagsModal()}>
                                                TAG
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </UncontrolledCollapse>
                        </div>
                        {
                            this.state.currentAccount == -1 ?
                                <div className="row text-center mt-4 pt-4">
                                    <div className="col-lg-6 col-md-6 col-xs-12 mx-auto">
                                        <h3>Choose your account with "Switch account" or <a href="/user/new-bot">creating a new one</a>!</h3>
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        this.state.visibleRows !== null ?

                                            <div className="row">
                                                <Asd width={this.state.fontSize} responsive striped size="sm" id="strategies">
                                                    <thead>
                                                        <tr>
                                                            <Th width="30px"><input type="checkbox" onClick={(e) => this.selectAllRows(e)} /></Th>
                                                            <Th
                                                                className="text-center"
                                                                width="30px"
                                                                onClick={() => this.editableField('symbol')}
                                                            >Pair&nbsp;
                                                                {
                                                                    this.state.columnsOrders['symbol'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }</Th>
                                                            <Th
                                                                className="text-center"
                                                                onClick={() => this.sortStrategies('status')}
                                                            >Status &nbsp;
                                                                {
                                                                    this.state.columnsOrders['status'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }</Th>
                                                            <Th
                                                                className="text-center"
                                                                onClick={() => this.sortStrategies('positionSide')}
                                                            >Strat &nbsp;
                                                                {
                                                                    this.state.columnsOrders['positionSide'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }
                                                            </Th>
                                                            <Th
                                                                style={this.state.editableField === 'orderSize' ? { minWidth: "126px" } : {}}
                                                                className="text-center order-size"
                                                                onClick={() => this.editableField('orderSize')}
                                                            >
                                                                Order<br />size &nbsp;
                                                                {
                                                                    this.state.columnsOrders['orderSize'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }</Th>
                                                            <Th
                                                                className="text-center grid-orders"
                                                                style={this.state.editableField === 'gridOrders' ? { minWidth: "126px" } : {}}
                                                                onClick={() => this.editableField('gridOrders')}>Grid<br />
                                                                Orders&nbsp;
                                                                {
                                                                    this.state.columnsOrders['gridOrders'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }</Th>
                                                            <Th
                                                                className="text-center first-order-step"
                                                                style={this.state.editableField === 'gridStep' ? { minWidth: "126px" } : {}}
                                                                onClick={() => this.editableField('gridStep')}>
                                                                1° Order<br />Step &nbsp;
                                                                {
                                                                    this.state.columnsOrders['gridStep'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }
                                                            </Th>
                                                            <Th
                                                                className="text-center step-factor"
                                                                onClick={() => this.editableField('stepFactor')}
                                                                style={this.state.editableField === 'stepFactor' ? { minWidth: "126px" } : {}}
                                                            >
                                                                Step<br />Factor&nbsp;
                                                                {
                                                                    this.state.columnsOrders['stepFactor'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }
                                                            </Th>
                                                            <Th
                                                                style={this.state.editableField === 'orderFactor' ? { minWidth: "126px" } : {}}
                                                                className="text-center order-factor"
                                                                onClick={() => this.editableField('orderFactor')}>
                                                                Order<br />Factor
                                                                &nbsp;
                                                                {
                                                                    this.state.columnsOrders['orderFactor'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }
                                                            </Th>
                                                            <Th
                                                                className="text-center take-step"
                                                                style={this.state.editableField === 'takeStep' ? { minWidth: "126px" } : {}}
                                                                onClick={() => this.editableField('takeStep')}
                                                            >
                                                                Take<br />Step&nbsp;
                                                                {
                                                                    this.state.columnsOrders['takeStep'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                                                }
                                                            </Th>
                                                            <Th
                                                                className="text-center stop-loss-offset"
                                                                style={this.state.editableField === 'stopLossOffset' ? { minWidth: "126px" } : {}}
                                                                onClick={() => this.editableField('stopLossOffset')}
                                                            >
                                                                Stop<br />Loss&nbsp;
                                                                {
                                                                    this.state.columnsOrders['stopLossOffset'] === 1 ?
                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        :
                                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                                                                }</Th>
                                                            <Th
                                                                className="text-center note"
                                                                style={this.state.editableField === 'note' ? { minWidth: "126px" } : {}}
                                                                onClick={() => this.editableField('note')}>Note</Th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.visibleRows.map((strategy, key) => <Tr key={key}>
                                                            <td className="text-left">
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={() => this.toggle(strategy.id)}
                                                                    checked={this.state.visibleRows[key] ? this.state.visibleRows[key].c.status : false}
                                                                />
                                                            </td>
                                                            <td className="text-center" className="text-left">{strategy.symbol}</td>
                                                            <td className="text-center">{chooseStatusIcon({ value: strategy.status })}</td>
                                                            <td className="text-center">{chooseSideIcon(strategy.positionSide)}</td>
                                                            <td className="text-center order-size">
                                                                {
                                                                    this.state.editableField === 'orderSize' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="number"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            value={strategy.orderSize}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.orderSize
                                                                }
                                                            </td>
                                                            <td className="text-center grid-orders">
                                                                {
                                                                    this.state.editableField === 'gridOrders' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="number"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            value={strategy.gridOrders}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.gridOrders
                                                                }
                                                            </td>
                                                            <td className="text-center first-order-step">
                                                                {
                                                                    this.state.editableField === 'gridStep' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="number"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            step={0.1}
                                                                            min={0}
                                                                            value={strategy.gridStep}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.gridStep
                                                                }
                                                            </td>
                                                            <td className="text-center step-factor">
                                                                {
                                                                    this.state.editableField === 'stepFactor' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="number"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            value={strategy.stepFactor}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.stepFactor
                                                                }
                                                            </td>
                                                            <td className="text-center order-factor">
                                                                {
                                                                    this.state.editableField === 'orderFactor' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="number"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            value={strategy.orderFactor}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.orderFactor
                                                                }
                                                            </td>
                                                            <td className="text-center take-step">
                                                                {
                                                                    this.state.editableField === 'takeStep' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="number"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            value={strategy.takeStep}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.takeStep
                                                                }</td>
                                                            <td className="text-center stop-loss-offset">
                                                                {
                                                                    this.state.editableField === 'stopLossOffset' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-control text-center mx-auto"
                                                                            defaultChecked={strategy.stopLossOffset == "true" ? true : false}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.stopLossOffset === 0 ? "No" : strategy.stopLossOffset
                                                                }
                                                            </td>
                                                            <td className="text-center note" width="170px">
                                                                {
                                                                    this.state.editableField === 'note' &&
                                                                        this.state.visibleRows[key]?.c.status ?
                                                                        <input
                                                                            type="text"
                                                                            className="form-control w-75 text-center mx-auto"
                                                                            value={strategy.note}
                                                                            onChange={(e) => this.updateBulkEdit(e.target.value)}
                                                                        />
                                                                        :
                                                                        strategy.note
                                                                }
                                                            </td>
                                                            <td>
                                                                <ButtonGroup size="sm">
                                                                    <Button
                                                                        type="button"
                                                                        className="btn-sm btn-primary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                currentModal: 'editStrategy',
                                                                                currentEditingStrategy: strategy,
                                                                                mode: 'duplicate'
                                                                            })
                                                                        }}>
                                                                        <span className="fa fa-copy" />
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        className="btn-sm btn-secondary"
                                                                        onClick={() => this.copyStrategyOnClipboard(strategy)}
                                                                    >
                                                                        <span className="fa fa-save" />
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        className="btn-sm btn-warning"
                                                                        onClick={() => this.setState({
                                                                            currentModal: 'editStrategy',
                                                                            currentEditingStrategy: strategy,
                                                                            mode: 'edit'
                                                                        })}>
                                                                        <span className="fa fa-pencil" />
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        className="btn-sm btn-danger"
                                                                        onClick={
                                                                            () => this.setState({
                                                                                currentModal: 'confirmDelete',
                                                                                currentEditingStrategy: strategy
                                                                            })
                                                                        }
                                                                        disabled={strategy.status !== 'stop' && strategy.status !== 'hardStop' && strategy.status !== ''}
                                                                    >
                                                                        <span className="fa fa-trash" />
                                                                    </Button>
                                                                </ButtonGroup>

                                                            </td>
                                                        </Tr>
                                                        )}
                                                    </tbody>
                                                </Asd>
                                            </div>
                                            :
                                            <div className="text-center mt-4">
                                                <Spinner type="grow" className="mt-4" color="success" />
                                            </div>
                                    }

                                </div>

                        }
                    </div>
                    : ''
            }
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Strategia copiata!"
                show={this.state.currentModal === 'copied'}
                type="success"
                onConfirm={this.hideAlert}
                onCancel={this.hideAlert}
            />
            < SweetAlert
                title="Choose tags you want to see!"
                type="warning"
                show={this.state.currentModal === 'tagsModal'}
                onConfirm={this.hideAlert}
                onCancel={this.hideAlert}
                cancelBtnText="Close"
                showCancel={true}
                showConfirm={false}
                cancelBtnBsStyle="success"
                confirmBtnBsStyle="success"
            >
                <div className="container mt-4 mb-4 ml-4">
                    <div className="row text-dark font-weight-normal">
                        {
                            this.state.userTags.map((tag, id) => {
                                return <div key={id} className="col-4 text-left">
                                    <p>
                                        <Input
                                            type="checkbox"
                                            defaultChecked={!this.state.showTags[this.state.userTags.indexOf(tag)]}
                                            name={tag}
                                            onChange={(e) => {
                                                this.setState({
                                                    visibleRows: []
                                                })
                                                // aggiorno array dove salvo se items visibili o meno
                                                let tmpArray = [...this.state.showTags]
                                                tmpArray[this.state.userTags.indexOf(tag)] = !this.state.showTags[this.state.userTags.indexOf(tag)]
                                                this.setState({
                                                    showTags: tmpArray
                                                })
                                                if (this.hiddenTags.indexOf(tag) > -1)
                                                    this.hiddenTags[this.hiddenTags.indexOf(tag)] = "-1"
                                                else
                                                    this.hiddenTags.push(tag)
                                                console.log('mostro rows che non hanno', this.hiddenTags)
                                                // cerco array che NON abbiano i TAG a FALSE
                                                console.log('devo mostrare', tmpArray.map((tag, i) => this.state.userTags[i] + " " + tag))
                                                let rows = []
                                                for (let i = 0; i < this.state.rows.length; i++) {
                                                    let found = false
                                                    for (let tagIndex = 0; tagIndex < this.state.rows[i].tags.length; tagIndex++) {
                                                        for (let hiddenIndex = 0; hiddenIndex < this.hiddenTags.length; hiddenIndex++) {
                                                            if (this.state.rows[i].tags[tagIndex] === this.hiddenTags[hiddenIndex])
                                                                found = true
                                                        }

                                                    }
                                                    if (found === false)
                                                        rows.push(this.state.rows[i])
                                                }
                                                console.log('rowsss', rows)
                                                this.setState({
                                                    visibleRows: rows
                                                })
                                            }}
                                        />{' '}
                                        {tag}
                                    </p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </SweetAlert >
            <SweetAlert
                title="Import strategy from your clipboard!"
                type="warning"
                show={this.state.showImportStrategyModalState}
                onConfirm={() => this.importStrategy()}
                onCancel={() => this.hideAlert()}
                cancelBtnText="Cancel"
                confirmBtnText="Import"
                showCancel={true}
                showConfirm={true}
                cancelBtnBsStyle="danger"
                confirmBtnBsStyle="success"
            >
                {
                    this.state.processedStrategy !== null ?
                        <div dangerouslySetInnerHTML={{ __html: this.state.processedStrategy }} className="row p-4" style={{ color: 'black' }} />
                        :
                        <p className="mt-4">
                            Paste here with right click then paste or CTRL+V
                            <input className={'form-control shadow-none mt-2 w-75 mx-auto ' + this.state.wrongImportedFormat} onChange={(e) => this.processImportedStrategy(e)}></input>
                        </p>
                }
            </SweetAlert>
            <SweetAlert
                title="Choose columns you want to see!"
                type="warning"
                show={this.state.currentModal === 'columnsModal'}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                cancelBtnText="Close"
                showCancel={true}
                showConfirm={false}
                cancelBtnBsStyle="success"
                confirmBtnBsStyle="success"
            >
                <div className="container mt-4 mb-4 ml-4">
                    <div className="row">
                        {
                            this.state.columns.map((column, id) => {
                                return <div className="col-4 text-left font-weight-normal" key={id}>
                                    <p>
                                        <Input
                                            type="checkbox"
                                            defaultChecked={column.visible}
                                            name={column.key}
                                            onChange={(e) => {
                                                console.log(e.target.checked)
                                                const nodes = document.getElementsByClassName(e.target.name)
                                                for (let index = 0; index < nodes.length; index++) {
                                                    console.log(e.target.checked)
                                                    if (e.target.checked) {
                                                        nodes[index].style.removeProperty('display');
                                                    } else {
                                                        nodes[index].style.display = 'none'
                                                    }
                                                }
                                            }}
                                        />{' '}
                                        {column.name}
                                    </p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </SweetAlert>
            <NotificationAlert ref="notifyRef" zIndex={9999} onClick={() => console.log("")} />

            <SweetAlert
                title=""
                show={this.state.currentModal === 'takeProfits'}
                closeBtnStyle={{ zIndex: 90, color: 'black', backgroundColor: 'white' }}
                style={{ width: '90%' }}
                showCloseButton={true}
                onCancel={() => this.hideAlert()}
                closeOnClickOutside={true}
                showCancel={false}
                onConfirm={() => console.log('Ciao')}
                showConfirm={false}
                confirmBtnText="Update"
            >
                <TakeProfits></TakeProfits>
            </SweetAlert>

            <SweetAlert
                title=""
                show={this.state.currentModal === 'newStrategy'}
                closeBtnStyle={{ zIndex: 90, color: 'black', backgroundColor: 'white' }}
                style={{ width: '90%', height: '80vh' }}
                showCloseButton={true}
                onCancel={() => this.hideAlert()}
                closeOnClickOutside={true}
                showCancel={false}
                onConfirm={() => console.log('Ciao')}
                showConfirm={false}
                confirmBtnText="Update"
            >
                <ChooseStrategy />
            </SweetAlert>

            <SweetAlert
                title=""
                show={this.state.currentModal === 'editStrategy'}
                closeBtnStyle={{ zIndex: 90, color: 'black', backgroundColor: 'white' }}
                style={{ width: '95%' }}
                showCloseButton={true}
                onCancel={() => this.hideAlert()}
                closeOnClickOutside={true}
                showCancel={false}
                onConfirm={() => console.log('Ciao')}
                showConfirm={false}
                confirmBtnText="Update"
            >
                <EditStrategy
                    strategy={this.state.currentEditingStrategy}
                    hideAlert={this.hideAlert}
                    mode={this.state.mode}
                    strategies={this.state.rows}
                ></EditStrategy>
            </SweetAlert>

            <SweetAlert
                title="You are going to delete this strategy permanently"
                type="danger"
                show={this.state.currentModal === 'confirmDelete'}
                onConfirm={this.deleteStrategy}
                onCancel={this.hideAlert}
                showCancel={true}
                confirmBtnText="Yes, DELETE"
                cancelBtnTex="No!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
            >
                <p className="text-dark font-weight-normal m-4">
                    {`${this.state.currentEditingStrategy?.positionSide} - ${this.state.currentEditingStrategy?.symbol}`}
                </p>
            </SweetAlert>

            <SweetAlert
                title={`${this.state.confirmBulkActionButton ? 'Confirm' : 'Confirmed'} ${this.state.pendingAction} action`}
                type="warning"
                show={this.state.pendingAction !== null}
                onConfirm={() => this.confirmBulkAction()}
                showConfirm={this.state.confirmBulkActionButton}
                onCancel={this.hideAlert}
                showCancel={this.state.confirmBulkActionButton}
                confirmBtnText="Yes, proceed"
                cancelBtnTex="No!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
            />

            <SweetAlert
                title="Risk calculator"
                type="info"
                onCancel={this.hideAlert}
                onConfirm={this.hideAlert}
                style={{ width: '90%' }}
                show={this.state.currentModal === 'risk'}
                showCloseButton={true}
            >
                <div className="text-dark text-left font-weight-normal">
                    <h6 className="pt-4">Total Risk</h6>
                    <Table responsive bordered striped size="sm" className="risk-calculator mb-4">
                        <thead>
                            <tr>
                                <Th className="text-center">Symbol</Th>
                                <Th className="text-center">Total Balance</Th>
                                <Th
                                    className="text-center"
                                    style={{ backgroundColor: '#fdc44c7a' }}
                                >
                                    % loss at last order
                                </Th>
                                <Th className="text-center">
                                    Loss at last order
                                </Th>
                                <Th className="text-center">Leverage impact on wallet %</Th>
                                <Th className="text-center">Leverage impact on wallet</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.risk?.quote.map((quote, key) => {
                                    return <tr key={key}>
                                        <td className="text-center">{quote.quote}</td>
                                        <td className="text-center">{quote.balance.toFixed(4)} {quote.quote}</td>
                                        <td
                                            className="text-center"
                                            style={{ backgroundColor: '#fdc44c7a' }}
                                        >
                                            {quote.totalGridLastLossPerc.toFixed(2)}%
                                        </td>
                                        <td className="text-center">{quote.totalGridLastLossValue.toFixed(4)} {quote.quote}</td>
                                        <td className="text-center">{quote.totalWalletImpactLeveragePerc.toFixed(2)}%</td>
                                        <td className="text-center">{quote.totalWalletImpactLeverageValue.toFixed(4)} {quote.quote}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>

                    <h6 className="pt-4">Risk</h6>
                    <Table responsive bordered striped size="sm" className="risk-calculator mb-4">
                        <thead>
                            <tr>
                                <Th className="text-center">Symbol</Th>
                                <Th className="text-center">Market Price</Th>
                                <Th className="text-center">First<br />Order<br />Size</Th>
                                <Th colSpan={2} className="text-center">Loss (stop loss)</Th>
                                <Th colSpan={3} className="text-center">Grid Exposure</Th>
                                <Th colSpan={2}></Th>
                                <Th colSpan={6} className="text-center">Last Grid Risk</Th>
                            </tr>
                            <tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th className="text-center">Grid Value</Th>
                                <Th className="text-center">Margin Value</Th>
                                <Th className="text-center">Margin %</Th>
                                <Th colSpan={2} className="text-center" style={{ backgroundColor: '#fdc44c7a' }}>Loss</Th>
                                <Th className="text-center">Last<br />Order<br />Price</Th>
                                <Th className="text-center">Last order price <br />to market price</Th>
                                <Th className="text-center">Average<br />Price</Th>
                                <Th className="text-center">Required %<br />change to profit</Th>
                                <Th className="text-center">Average price<br />to market price</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.risk?.risk.map((risk, key) => {
                                    return <tr key={key}>
                                        <td>{risk.symbol}<br />({risk.positionSide} {risk.leverage}x)</td>
                                        <td>{risk.marketPrice.toFixed(4)} {risk.quoteAsset}</td>
                                        <td>{risk.sizeFirstStep.toFixed(4)} {risk.quoteAsset}</td>
                                        <td>{risk.lossStopLossValue.toFixed(2)}</td>
                                        <td>{risk.lossStopLossPerc.toFixed(2)}%</td>
                                        <td>{risk.gridValue.toFixed(4)} {risk.quoteAsset}</td>
                                        <td>{risk.marginUsedValue.toFixed(4)} {risk.quoteAsset}</td>
                                        <td>{risk.marginUsedPercentage.toFixed(2)}%</td>
                                        <td style={{ backgroundColor: '#fdc44c7a' }}>{risk.gridLastLossValue.toFixed(4)} {risk.quoteAsset}</td>
                                        <td style={{ backgroundColor: '#fdc44c7a' }}>{risk.gridLastLossPerc.toFixed(2)}%</td>
                                        <td>{risk.gridLastPrice.toFixed(4)} {risk.quoteAsset}</td>
                                        <td>{risk.gridLastPriceVsMarketPricePerc.toFixed(2)}%</td>
                                        <td>{risk.gridLastEntryPrice.toFixed(4)} {risk.quoteAsset}</td>
                                        <td>{risk.gridLastEntryVsGridLastPricePerc.toFixed(2)}%</td>
                                        <td>{risk.gridLastEntryVsMarketPricePerc.toFixed(2)}%</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </SweetAlert>
        </div>
    }
}

export default UserStrategies;
