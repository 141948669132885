import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

class ChooseStrategy extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="content" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="box w-100">
                <h3 className="text-center mb-8 mt-8">Choose the type of new strategy!</h3>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <a href="/user/standard" className="btn btn-block btn-success p-4" size="lg">
                                <h4 className="my-2">Standard</h4>
                            </a>
                        </div>
                        <div className="col-md-6 col-12">
                            <a href="/user/custom" title="Coming soon feature" className="btn btn-block btn-primary p-4" size="lg" disabled>
                                <h4 className="my-2">Custom (soon!)</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ChooseStrategy;