import React, { useState, useRef, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Spinner,
    Card
} from 'reactstrap';
import { firestore, auth } from '../../../firebase';
import { UserContext } from "../../../providers/UserProvider";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ApiContext } from '../../../providers/ApiProvider';
import axios from 'axios'
import './newBot.css'
import "react-notification-alert/dist/animate.css";
import NotificationAlert from 'react-notification-alert';
import ApiService from 'components/ApiService';
import ReactSwitch from 'react-switch';

const NewBot = () => {
    const notifyRef = useRef(null);
    const apiService = new ApiService()

    const [botTypes] = useState([{
        value: 'binance',
        text: 'Binance Future'
    }, {
        value: 'okex',
        text: 'OKEX Margin'
    }])
    const [selectedBotType, setBotType] = useState(botTypes[0].value)
    const [apiKey, setApiKey] = useState('')
    const [apiSecret, setApiSecret] = useState('')
    const [botName, setBotName] = useState('')
    const [botPassphrase, setBotPassphrase] = useState('')
    const [botTestMode, setBotTestMode] = useState(false)

    const [telegramChatId, setTelegramChatId] = useState('')
    const [apiToken, setApiToken] = useState('')
    const [resultModal, setModal] = useState('')

    const [wSaverEnabled, enableWSaver] = useState(false)
    const [wSaverBaseCoin, setBaseCoin] = useState('USDT')
    const [wSaverThreshold, setThreshold] = useState(0)
    const [wSaverFrequency, setFrequency] = useState('24h')

    const [loading, setLoading] = useState(false)

    const user = useContext(UserContext);
    const api = useContext(ApiContext);

    const [currentBots, setCurrentBots] = useState(null)

    const BotType = botTypes.map(BotType => BotType)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorOnTelegramChatId, setErrorOnTelegramChatId] = useState('')
    const [currentEditingBot, setCurrentEditingBot] = useState(null)
    const [currentRemovingBot, setCurrentRemovingBot] = useState(null)

    const [itemEditing, setItemEditing] = useState('')
    const [currentModal, setCurrentModal] = useState('')

    const notification = (obj) => {
        notifyRef.current.notificationAlert({
            place: 'bc',
            message: (
                <div>
                    <div>
                        <b>{obj.text}</b>
                    </div>
                </div>
            ),
            type: obj.type,
            autoDismiss: 2
        });
    }

    const generateToken = (length, onlyLowercaseLetters = false) => {
        //edit the token allowed characters
        let a = ''
        if (onlyLowercaseLetters)
            a = "abcdefghijklmnopqrstuvwxyz".split("");
        else
            a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        let b = [];
        for (let i = 0; i < length; i++) {
            let j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    useEffect(() => {
        console.log('init config page')
        auth.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                /*const userRef = firestore.collection('users').doc(userAuth.uid);
                const userDetail = await userRef.get()
                const telegramChatIds = userDetail.data().telegramChatIds
                const apiToken = userDetail.data().extAuthToken

                setTelegramChatId(telegramChatIds)
                setApiToken(apiToken)*/

                firestore
                    .collection('bots')
                    .where('userId', '==', userAuth.uid)
                    .onSnapshot((snapshot) => {
                        const accounts = []
                        snapshot.docs.forEach(account => {
                            let accountObj = { id: account.id, ...account.data(), editDisabled: true }
                            accounts.push(accountObj)
                        })
                        setCurrentBots(accounts)
                    });
            }
        })
    }, []);

    const handleAddrTypeChange = (e) => {
        console.log(e.target.value)
        setBotType(e.target.value)
    }

    const newBotValidation = () => {
        if (
            apiKey === '' ||
            apiSecret === '' ||
            botName === ''
        ) {
            setErrorMessage('Some fields are missing..')
            return false
        }
        return true
    }

    const resetPassword = async () => {
        auth.sendPasswordResetEmail(user.providerData[0].uid)
            .then(function () {
                console.log('email sent')
                notification({
                    type: 'success',
                    text: 'Your new bot has been successfully created!'
                })
            })
            .catch(function (error) {
                console.log('error on email')
                notification({
                    type: 'danger',
                    text: 'Could not send reset email, contact administrators on Telegram!'
                })
            });
    }

    const getFirestoreExchangeType = (value) => {
        switch (value) {
            case 'binance':
                return 'binancef'
            case 'okex':
                return 'okexm';
            default:
                throw ("invalid exchange type", value)
        }
    }

    const handleNewBotSubmit = async () => {
        if (newBotValidation()) {
            try {
                setLoading(true)
                const id = generateToken(40, true)
                const res = await apiService.newBot({
                    id,
                    accessKey: apiKey,
                    secretKey: apiSecret,
                    passphrase: botPassphrase,

                    exchange: selectedBotType,

                    simulationMode: false
                }, user)
                if (res !== -1) {
                    const doc = await firestore.doc(`bots/${id}`).set({
                        exchangeType: getFirestoreExchangeType(selectedBotType),
                        alias: botName,
                        apiKey: apiKey,
                        status: "active",
                        userId: user.uid,

                        saverEnabled: wSaverEnabled,
                        saverAsset: wSaverBaseCoin,
                        saverFrequency: wSaverFrequency,
                        saverLevel: wSaverThreshold

                    })
                    notification({
                        type: 'success',
                        text: 'Your new bot has been successfully created!'
                    })
                } else {
                    notification({
                        type: 'danger',
                        text: 'Error'
                    })
                }
                setLoading(false)
            } catch (e) {
                console.log(e)
                setLoading(false)
                notification({
                    type: 'danger',
                    text: 'Seems like someone is broken, contact administrators on Telegram!'
                })
            }
            setBotName("")
            setApiKey("")
            setApiSecret("")
            setBotType(botTypes[0].value)
        }
    };

    const handleSaveTelegramChatId = () => {
        setItemEditing('telegram')
        console.log('Update telegram id..')
        firestore.collection("users").doc(user.uid).set({
            telegramChatIds: telegramChatId
        }, { merge: true })
            .then(() => {
                console.log("Document successfully written!");
                notification({
                    type: 'success',
                    text: 'Telegram Chat ID updated succesfully'
                })
                setItemEditing('')
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
                notification({
                    type: 'danger',
                    text: 'Could not update Telegram Chat ID'
                })
                setItemEditing('')
            });
        /*if (telegramChatId === '' || !parseInt(telegramChatId) || true ) {
        } else {
        }*/
    }

    const handleApiToken = (confirmed) => {
        if (confirmed) {
            setItemEditing('api-token')
            const newApiToken = generateToken(32, false)
            setApiToken(newApiToken)
            setCurrentModal('')
            firestore.collection("users").doc(user.uid).set({
                extAuthToken: newApiToken
            }, { merge: true })
                .then(() => {
                    console.log("Document successfully written!");
                    notification({
                        type: 'success',
                        text: 'API Token updated succesfully'
                    })
                    setItemEditing('')
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                    notification({
                        type: 'danger',
                        text: 'Could not update API Token'
                    })
                    setItemEditing('')
                });
        } else {
            setCurrentModal('apiToken')
        }
    }

    const updateBotDetail = (id, field, value) => {
        let selectedIndex = currentBots.findIndex(x => x.id === id)
        let bot = currentBots[selectedIndex]
        /*if (field.indexOf('walletSaver') > -1) {
            bot['walletSaver'][field.replace('walletSaver.', '')] = value
        }*/
        bot[field] = value
        setCurrentBots([
            ...currentBots.slice(0, selectedIndex),
            bot,
            ...currentBots.slice(selectedIndex + 1)
        ])
    }

    const saveBotUpdate = (id) => {
        console.log('id', id)
        const editedBot = currentBots.filter(bot => bot.id === id)[0]
        editedBot.editDisabled = true
        if (editedBot.saverAsset && (editedBot.saverEnabled === true || editedBot.saverEnabled === false) && editedBot.saverFrequency && editedBot.saverLevel >= 0) {
            updateBotDetail(id, 'editDisabled', true)

            firestore.collection("bots").doc(id).set({
                alias: editedBot.alias,
                saverAsset: editedBot.saverAsset,
                saverEnabled: editedBot.saverEnabled,
                saverFrequency: editedBot.saverFrequency,
                saverLevel: parseInt(editedBot.saverLevel)
            }, { merge: true })
                .then(() => {
                    console.log("Document successfully written!");
                    notification({
                        type: 'success',
                        text: 'Bot updated succesfully'
                    })
                    setItemEditing('')
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                    notification({
                        type: 'danger',
                        text: 'general error'
                    })
                    setItemEditing('')
                });

            console.log(currentBots.filter(bot => bot.id === id)[0])
        } else {
            notification({
                type: 'warning',
                text: 'All fields are required'
            })
        }
    }

    const deleteAccount = async (botId) => {
        setCurrentRemovingBot(null)
        firestore.collection('bots').doc(botId).delete().then(async () => {
            console.log("Document successfully deleted!");
            const res = await apiService.deleteBot(botId, user)
            console.log('result delete from api server', res)
            notification({
                type: 'success',
                text: 'Exchange account deleted successfully'
            })
        }).catch((error) => {
            notification({
                type: 'danger',
                text: 'Could not delete exchange account'
            })
            console.error("Error removing document: ", error);
        });
    }

    return <div className="content">
        {resultModal}
        {
            user ?
                <div>
                    <h4 className="my-1 pt-0">Connect new account</h4>
                    <Card className="p-4 mt-2">
                        <div className="row">
                            <div className="col-12">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-2 col-xs-12">
                                                    <label>Platform</label>
                                                    <select
                                                        onChange={e => handleAddrTypeChange(e)}
                                                        className="form-control form-inline-height-fixed"
                                                    >
                                                        {
                                                            BotType.map((platform, key) => <option key={key} value={platform.value}>{platform.text}</option>)
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-md-2 col-xs-12">
                                                    <label>Bot name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-inline-height-fixed"
                                                        placeholder='Custom name'
                                                        value={botName}
                                                        onChange={(event) => setBotName(event.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-2 col-xs-12">
                                                    <label>API KEY</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="new-password"
                                                        className="form-control form-inline-height-fixed"
                                                        placeholder="Api Key"
                                                        value={apiKey}
                                                        onChange={(event) => setApiKey(event.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-3 col-xs-12">
                                                    <label>API SECRET</label>
                                                    <input
                                                        type="password"
                                                        autoComplete="new-password"
                                                        className="form-control form-inline-height-fixed"
                                                        placeholder="Api Secret"
                                                        value={apiSecret}
                                                        onChange={(event) => setApiSecret(event.target.value)}
                                                    />
                                                </div>
                                                {
                                                    selectedBotType === 'okex' &&
                                                    <div className="col-md-3 col-xs-12">
                                                        <label>Passphrase</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-inline-height-fixed"
                                                            placeholder='A secret password'
                                                            value={botPassphrase}
                                                            onChange={(event) => setBotPassphrase(event.target.value)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    /*selectedBotType === 'okex' &&
                                                    <div className="col-md-1 col-xs-12 text-center">
                                                        <label>Test mode</label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-control form-inline-height-fixed"
                                                            value={botTestMode}
                                                            onChange={() => setBotTestMode(!botTestMode)}
                                                        />
                                                    </div>*/
                                                }
                                            </div>
                                            {
                                                selectedBotType === 'binance' || selectedBotType === 'okex'
                                                    ?
                                                    <div className="pl-4">
                                                        <h5>Wallet Saver</h5>
                                                        <div className="row">
                                                            <div className="col-md-3 col-6">
                                                                <p>Enable wallet saver</p>
                                                                <ReactSwitch
                                                                    onChange={e => enableWSaver(!wSaverEnabled)}
                                                                    checked={wSaverEnabled}
                                                                    className="react-switch"
                                                                />
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <label>Base coin</label>
                                                                <select
                                                                    className="form-control form-inline-height-fixed"
                                                                    value={wSaverBaseCoin}
                                                                    disabled={!wSaverEnabled}
                                                                    onChange={(event) => setBaseCoin(event.target.value)}
                                                                >
                                                                    <option value="USDT">USDT</option>
                                                                    {selectedBotType === 'binance' && <option value="BUSD">BUSD</option>}
                                                                    {selectedBotType === 'okex' && <option value="BTC">BTC</option>}
                                                                </select>
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <label>Level</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control form-inline-height-fixed"
                                                                    placeholder="Threshold"
                                                                    disabled={!wSaverEnabled}
                                                                    value={wSaverThreshold}
                                                                    min={0}
                                                                    onChange={(event) => setThreshold(parseFloat(event.target.value))}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <label>Frequency</label>
                                                                <select
                                                                    className="form-control form-inline-height-fixed"
                                                                    value={wSaverFrequency}
                                                                    disabled={!wSaverEnabled}
                                                                    onChange={(event) => setFrequency(event.target.value)}
                                                                >
                                                                    <option value="30m">30m</option>
                                                                    <option value="6h">6h</option>
                                                                    <option value="24h">24h</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                        <div className="col-md-4 col-xs-12 mx-auto mt-4">
                                            <button
                                                className="btn btn-success w-100"
                                                type="button"
                                                onClick={handleNewBotSubmit}
                                            >
                                                {!loading ? 'Connect new account' : <Spinner size="sm" color="primary" />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center text-danger">
                                            <h6> {errorMessage} </h6>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </Card>


                    {currentBots && currentBots.length > 0 && <h4 className="pl-0 mb-2">Your Accounts</h4>}
                    {currentBots && currentBots.length === 0 && <h4 className="p-0 mb-2">You don't have any account yet, create you first one below!</h4>}
                    {
                        currentBots === null ?
                            <div className="text-center">
                                <h4>Loading connected bots</h4>
                                <Spinner type="grow" color="success" />
                            </div>
                            :
                            currentBots.map(
                                (bot, key) =>
                                    <Card key={key}>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-xs-12 col-md-12 col-lg-10">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-2">
                                                            <h4 className="form-inline-height-fixed">Exchange Type</h4>
                                                            <p className="form-control form-inline-height-fixed rounded-0 bg-transparent">{bot.exchangeType}</p>
                                                        </div>
                                                        <div className="col-sm-12 col-md-2">
                                                            <h4 className="form-inline-height-fixed">API</h4>
                                                            <p className="form-control form-inline-height-fixed rounded-0 bg-transparent text-truncate">{bot.apiKey}</p>
                                                        </div>
                                                        <div className="col-sm-12 col-md-2">
                                                            <h4 className="form-inline-height-fixed">Bot Name</h4>
                                                            <input
                                                                type="text"
                                                                className="form-control form-inline-height-fixed text-truncate"
                                                                value={bot.alias}
                                                                disabled={bot.editDisabled}
                                                                onChange={(event) => updateBotDetail(bot.id, 'alias', event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-1 col-6 text-center">
                                                            <h4 className="form-inline-height-fixed">Saver</h4>
                                                            <ReactSwitch
                                                                disabled={!((bot.exchangeType === 'binancef' || bot.exchangeType === 'okexm') && !bot.editDisabled)}
                                                                onChange={e => {
                                                                    if (!bot.saverEnabled && !bot.saverAsset && !bot.saverFrequency && !bot.saverLevel) {
                                                                        updateBotDetail(bot.id, 'saverLevel', 0)
                                                                        updateBotDetail(bot.id, 'saverAsset', 'USDT')
                                                                        updateBotDetail(bot.id, 'saverFrequency', '24h')
                                                                    }
                                                                    updateBotDetail(bot.id, 'saverEnabled', !bot.saverEnabled)
                                                                }}
                                                                checked={bot.saverEnabled ? bot.saverEnabled : false}
                                                                className="react-switch"
                                                            />
                                                        </div>
                                                        <div className="col-md-2 col-6">
                                                            <h4 className="form-inline-height-fixed">Base coin</h4>
                                                            <select
                                                                className="form-control form-inline-height-fixed"
                                                                value={bot.saverAsset ? bot.saverAsset : wSaverBaseCoin}
                                                                disabled={!((bot.exchangeType === 'binancef' || bot.exchangeType === 'okexm') && !bot.editDisabled)}
                                                                onChange={(event) => updateBotDetail(bot.id, 'saverAsset', event.target.value)}
                                                            >
                                                                <option value="USDT">USDT</option>
                                                                {bot.exchangeType === 'binancef' && <option value="BUSD">BUSD</option>}
                                                                {bot.exchangeType === 'okexm' && <option value="BTC">BTC</option>}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-1 col-6">
                                                            <h4 className="form-inline-height-fixed">Level</h4>
                                                            <input
                                                                type="number"
                                                                className="form-control form-inline-height-fixed"
                                                                placeholder="Threshold"
                                                                disabled={!((bot.exchangeType === 'binancef' || bot.exchangeType === 'okexm') && !bot.editDisabled)}
                                                                value={bot.saverLevel ? bot.saverLevel : wSaverThreshold}
                                                                min={0}
                                                                onChange={(event) => updateBotDetail(bot.id, 'saverLevel', parseFloat(event.target.value))}
                                                            />
                                                        </div>
                                                        <div className="col-md-1 col-6">
                                                            <h4 className="form-inline-height-fixed">Frequency</h4>
                                                            <select
                                                                className="form-control form-inline-height-fixed"
                                                                value={bot.saverFrequency}
                                                                disabled={!((bot.exchangeType === 'binancef' || bot.exchangeType === 'okexm') && !bot.editDisabled)}
                                                                onChange={(event) => updateBotDetail(bot.id, 'saverFrequency', event.target.value)}
                                                            >
                                                                <option value="30m">30m</option>
                                                                <option value="6h">6h</option>
                                                                <option value="24h">24h</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            /*bot.exchangeType === 'okex' &&
                                                            <div className="col-md-1 col-6 text-center">
                                                                <h4 className="form-inline-height-fixed">Test</h4>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-control form-inline-height-fixed"
                                                                    checked={bot.botTestMode}
                                                                    disabled={true}
                                                                    onChange={(event) => updateBotDetail(bot.id, 'botTestMode', event.target.value)}
                                                                />
                                                            </div>*/
                                                        }
                                                        {
                                                            bot.exchangeType === 'binanceffe' && !bot.editDisabled &&
                                                            <h6 className="text-center p-4">
                                                                With this option the found exceeding {currentEditingBot?.saverLevel} USD will be
                                                                automatically moved to the spot wallet every {currentEditingBot?.saverFrequency}.
                                                                Do you confirm the option activation?
                                                            </h6>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-1 text-center mx-auto">
                                                    <div className="row h-100 d-flex align-items-center">
                                                        {
                                                            bot.editDisabled === true &&
                                                            <div className="col-sm-12 col-md-12 btn-group">
                                                                <Button
                                                                    className="btn-icon w-50"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setCurrentEditingBot(bot)
                                                                        updateBotDetail(bot.id, 'editDisabled', false)
                                                                    }}
                                                                >
                                                                    <i className="fa fa-edit" />
                                                                </Button>
                                                                <Button
                                                                    className="btn-icon w-50"
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => setCurrentRemovingBot(bot)}
                                                                >
                                                                    <i className="fa fa-trash" />
                                                                </Button>
                                                            </div>
                                                        }
                                                        {
                                                            bot.editDisabled === false &&
                                                            <div className="col-sm-12 col-md-12 btn-group">
                                                                <Button
                                                                    className="btn-icon btn-block w-50"
                                                                    color="success"
                                                                    type="button"
                                                                    onClick={() => saveBotUpdate(bot.id)}
                                                                >
                                                                    <i className="fa fa-save" />
                                                                </Button>
                                                                <Button
                                                                    className="btn-icon w-50"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => updateBotDetail(bot.id, 'editDisabled', true)}
                                                                >
                                                                    <i className="fa fa-undo" />
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                            )
                    }


                    <SweetAlert
                        type="danger"
                        show={currentRemovingBot !== null}
                        showCancel={true}
                        title="Delete confirmation"
                        confirmBtnText="Ok, delete"
                        cancelBtnText="No!"
                        onCancel={() => setCurrentRemovingBot(null)}
                        onConfirm={() => deleteAccount(currentRemovingBot.id)}
                    >
                        <div className="font-weight-bold">
                            Do you really want to delete following account:
                            <p className="p-4">
                                API KEY: {currentRemovingBot?.apiKey} <br />
                                Account: {currentRemovingBot?.exchangeType} <br />
                                Name: {currentRemovingBot?.alias} <br />
                            </p>
                        </div>
                    </SweetAlert>
                    <SweetAlert
                        type="warning"
                        show={currentModal === 'apiToken'}
                        showCancel={true}
                        title="New API Token"
                        confirmBtnText="Yes, proceed and block all other apps!"
                        cancelBtnText="No!"
                        onCancel={() => setCurrentModal('')}
                        onConfirm={() => handleApiToken(true)}
                    >
                        <div className="font-weight-bold">
                            Are you sure?
                        </div>
                    </SweetAlert>
                    <NotificationAlert ref={notifyRef} zIndex={9999} onClick={() => console.log("hey")} />
                </div>
                :
                <div></div>
        }
    </div >
}

export default NewBot;