
const axios = require('axios').default

class ApiService {
    API_PROD = 'https://api.terrabot.club/v1'
    API_DEV = 'https://api.qa.terrabot.club/v1'
    URL = ''
    ENV = 'PROD'

    constructor() {
        console.log('started rest module on ', this.ENV)
        if (this.ENV === 'PROD')
            this.URL = this.API_PROD
        else
            this.URL = this.API_DEV
    }

    async operation(command, ids, headers) {
        try {
            const result = await axios.post(
                `${this.URL}/strategies/operation`, {
                command,
                strategyIds: ids
            },
                headers)
            console.log('result', result)
        } catch (e) {
            if (e.response.status === 400) {
                return {
                    code: 400,
                    message: e.response.data.errors[0].message
                }
            }
        }
    }

    async risk(botId, ids, headers) {
        try {
            const result = await axios.post(
                `${this.URL}/bot/${botId}/riskcalculator`,
                {
                    command: "",
                    strategyIds: ids
                },
                headers
            )
            console.log('result', result)
            return result.data
        } catch (e) {
            console.log(e)
        }
    }

    async takeProfits(botId, headers, body) {
        try {
            const result = await axios.post(
                `${this.URL}/bot/${botId}/export/takeprofits`,
                {
                    startDate: body.from,
                    endDate: body.to
                },
                headers
            )
            return result.data
        } catch (e) {
            console.log(e)
        }
    }

    async profitsAndLosses(botId, headers, body) {
        try {
            const result = await axios.post(
                `${this.URL}/bot/${botId}/pnlreport`,
                {
                    startDate: body.from,
                    endDate: body.to
                },
                headers
            )
            return result.data
        } catch (e) {
            console.log(e)
        }
    }

    async positions(headers) {
        try {
            const result = await axios.post(`${this.URL}/positions`, {}, headers)
        } catch (e) {
            console.log(e)
        }
    }

    async newBot(body, user) {
        try {
            const res = await axios.post(`${this.URL}/bot/${body.id}/credential`,
                {
                    "accessKey": body.accessKey,
                    "secretKey": body.secretKey,
                    "exchange": body.exchange,
                    "passphrase": body.passphrase,
                    "simulationMode": body.simulationMode
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.Aa
                }
            })
            return 1
        } catch (e) {
            console.log(e)
            return -1
        }
    }

    async deleteBot(id, user) {
        try {
            const res = await axios.delete(`${this.URL}/bot/${id}/credential`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.Aa
                }
            })
        } catch (e) {
            console.log(e)
            return -1
        }
    }

    async getSymbols(exchange, user) {
        const res = await axios.get(
            `${this.URL}/exchange/${exchange}/symbols`, {
            headers: {
                'authorization': `Bearer ${user.Aa}`
            }
        })
        return res.data ? res.data : []
    }
}

export default ApiService;