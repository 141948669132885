import React, { useState } from 'react';
import MyEnv from '../../../environment';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Spinner,
    Button
} from 'reactstrap';

/*
- takeStep (come nella attuale)
- callBack (come nella attuale)
- gridOrders lo ricaviamo
---- gridStep
---- orderSize
*/


const NewCustomStrategy = () => {
    const typeGrid = 'custom'

    const defaultObject = {
        orderSize: 1,
        takeStep: 2
    }

    const [steps, setSteps] = useState([])
    const [loading, setLoading] = useState(false)

    return <div className="content" style={{ marginTop: '65px' }}>
        <h4 className="pt-0 mt-1 text-center">General</h4>
        <div className="row">
            <div className="col-6 col-md-3">
                <div className="form-group">
                    <label>Exchange</label>
                    <select className="form-control">
                        <option>Binance Future</option>
                        <option>Binance Spot</option>
                    </select>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="form-group">
                    <label>Select Pair</label>
                    <select className="form-control">
                        <option>BTCUSDT</option>
                        <option>ETHUSDT</option>
                    </select>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="form-group">
                    <label>Strategy</label>
                    <select className="form-control">
                        <option>LONG</option>
                        <option>SHORT</option>
                    </select>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="form-group">
                    <label>Take Step (%)</label>
                    <input type="number" className="form-control" placeholder="1" />
                </div>
            </div>
        </div>
        <hr className="w-75 ml-0 mx-auto" />
        <h4 className="pt-0 mt-1 text-center">Grid Section</h4>
        {/*<Button
                color="danger"
                className="fa fa-minus"
                onClick={() => setSteps([steps.shift()])}
            ></Button>*/}
        <div className="row">
            <Button
                color="primary"
                className="fa fa-plus mx-auto"
                onClick={() => {
                    setSteps([...steps, {
                        id: steps.length,
                        orderSize: 1,
                        gridStep: 1
                    }])
                }}
            ></Button>
        </div>
        {
            steps.map((item, id) => (
                <div className="row" key={id}>
                    <div className="col-md-1 col-2 d-flex align-items-stretch justify-content-end">
                        <Button
                            color="danger"
                            className="fa fa-trash"
                            size="sm"
                            onClick={() => setSteps(steps.filter(r => r.id !== item.id))}
                        ></Button>
                    </div>
                    <div className="col-md-3 col-5">
                        <div className="form-group">
                            <label>Order Size</label>
                            <input
                                type="number"
                                className="form-control"
                                value={steps[id].orderSize}
                                onChange={(e) => {
                                    let newArr = [...steps]
                                    newArr[id].orderSize = e.target.value
                                    setSteps(newArr);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-5">
                        <div className="form-group">
                            <label>Take Step (%)</label>
                            <input
                                type="number"
                                className="form-control"
                                value={steps[id].gridStep}
                                onChange={(e) => {
                                    let newArr = [...steps]
                                    newArr[id].gridStep = e.target.value
                                    setSteps(newArr);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ))
        }
        <div className="row mt-2 fixed-bottom">
            <div className="col-md-4 col-12 mx-auto">
                <Button
                    className="btn btn-success btn-block m-0"
                    size="bg"
                >
                    {!loading ? 'Create new strategy' : <Spinner size="sm" color="primary" />}
                </Button>
            </div>
        </div>
    </div>
}

export default NewCustomStrategy;