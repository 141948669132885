import React, { useState, useRef, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Container,
  Col,
  Row,
  NavLink,
} from 'reactstrap';
import { firestore, auth } from '../../../firebase';
import { UserContext } from "../../../providers/UserProvider";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ApiContext } from '../../../providers/ApiProvider';
import axios from 'axios'
import "react-notification-alert/dist/animate.css";
import "./signals.css"
import NotificationAlert from 'react-notification-alert';
import ApiService from 'components/ApiService';
import ReactSwitch from 'react-switch';
import Fuse from 'fuse.js';
import SelectSearch from 'react-select-search';

const Signals = () => {
  const notifyRef = useRef(null);
  const apiService = new ApiService()
  const [signals, setSignals] = useState([])

  // user actions
  const [actions, setUserActions] = useState([])
  // selected action
  const [selectedActionId, setActionId] = useState(null)
  // selected api actions
  const [apiActions, setApiActions] = useState([])

  const [selectedSignalId, setSignalId] = useState('')
  const user = useContext(UserContext);
  const [loaders, setLoaders] = useState({})
  const [modal, setModal] = useState(null)
  const [newActionName, setNewActionName] = useState('')
  const [actionType, setActionType] = useState('action')
  const [strategies, setStrategies] = useState([])

  const [selectedStrategyId, setStrategyId] = useState()
  const [selectedCommand, setCommand] = useState()

  const [botId, setBotId] = useState('')

  const [section, setSection] = useState('')

  const [editedAction, editAction] = useState({
    orderSize: '',
    orderFactor: '',
    takeStep: '',
    takeStepLimit: '',
    stepfactor: '',
    gridOrders: '',
    gridStep: '',
    positionSide: '',
    description: '',
    stopLossOffset: ''
  })

  const notification = (obj) => {
    notifyRef.current.notificationAlert({
      place: 'bc',
      message: (
        <div>
          <div>
            <b>{obj.text}</b>
          </div>
        </div>
      ),
      type: obj.type,
      autoDismiss: 2
    });
  }

  useEffect(() => {
    let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
    setBotId(atob(storage.account))
    console.log('init config page')
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        firestore
          .collection('signalActions')
          .where("botId", '==', atob(storage.account))
          .where('userId', '==', userAuth.uid)
          .onSnapshot((snapshot) => {
            // all actions
            let allActionsArray = []
            // only single istance by name, for selection
            let actions = []
            snapshot.docs.forEach(account => {
              // push on the first array
              allActionsArray.push({
                id: account.id,
                ...account.data()
              })
              // if not present inside the second array, push it
              if (!actions.filter(action => action.name === `${account.data().signalId} - ${account.data().actionName}`)[0]) {
                console.log(account.data())
                let accountObj = {
                  name: `${account.data().signalId} - ${account.data().actionName}`,
                  value: account.data().actionName,
                  original: account.data()
                }

                actions.push(accountObj)
              }
            })



            setUserActions(actions.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }))

            firestore
              .collection('strategies')
              .where("botId", '==', atob(storage.account))
              .where('userId', '==', userAuth.uid)
              .onSnapshot((snapshot) => {
                let tmpArray = []

                if (snapshot.size === 0) {
                  this.setState({
                    rows: [],
                    visibleRows: []
                  })
                } else {
                  snapshot.forEach((doc) => {
                    const newStrategy = { ...doc.data(), id: doc.id }
                    for (let i = 0; i < allActionsArray.length; i++) {
                      // console.log(allActionsArray[i])
                      // console.log(`${allActionsArray[i].strategyId} === ${doc.id}`)
                      if (allActionsArray[i].strategyId === doc.id) {
                        allActionsArray[i].strategyDetails = newStrategy
                      }
                    }
                    tmpArray.push(newStrategy)
                  })
                  setStrategies(tmpArray)
                  // console.log('imposto nuove actions a', actions)
                  setApiActions(allActionsArray)
                }
              })


            // console.log('actions', actions)
          });

        firestore
          .collection('signalElements')
          .where('state', '==', true)
          .onSnapshot((snapshot) => {
            let signals = []
            snapshot.docs.forEach(account => {
              let accountObj = { id: account.id, ...account.data(), editDisabled: true }
              signals.push(accountObj)
              // console.log(accountObj)
            })
            signals = signals.sort((a, b) => {
              if (a.signalId < b.signalId) return -1;
              if (a.signalId > b.signalId) return 1;
              return 0;
            });
            signals = signals.map(signal => ({
              name: signal.signalId,
              value: signal.signalId
            }))
            setSignals(signals)
          });

      }
    })
  }, []);

  const handlePairChange = async (e) => {
    console.log(e)
    setSignalId(e)
  }

  const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
      keys: ['name'],
      threshold: 0.3,
    });

    return (value) => {
      if (!value.length) {
        return options;
      }

      return fuse.search(value);
    };
  }

  const deleteAction = async (id) => {
    setLoaders({
      ...loaders,
      ['deleteAction']: true
    })

    console.log(id)
    await firestore.collection('signalActions').doc(id).delete()
    notification({
      type: 'success',
      text: 'Action deleted!'
    })

    setLoaders({
      ...loaders,
      ['deleteAction']: false
    })
  }

  const hideAlert = () => {
    setModal(null)
  }

  const handleOptionChange = (e) => {
    setActionType(e.target.value)
  }

  const handleStrategyChange = (e) => {
    console.log(e)
  }

  const handleCommandChange = (e) => {
    console.log(e)
  }

  const newActionForm = () => {
    return <form className="row mt-4">
      <div className="col-6 mt-4">
        <SelectSearch
          options={strategies.map(s => ({
            name: `${s.symbol} ${s.positionSide}`,
            value: s.id
          }))}
          value={selectedStrategyId}
          onChange={(e) => setStrategyId(e)}
          search
          filterOptions={fuzzySearch}
          name="userStrategies"
          placeholder="Choose your strategy" />
      </div>
      <div className="col-6 mt-4">
        <SelectSearch
          options={[
            {
              name: "Start",
              value: "start"
            }, {
              name: "Stop",
              value: "stop"
            }, {
              name: "Soft Stop",
              value: "softStop"
            }, {
              name: "Hard Stop",
              value: "hardStop"
            }
          ]}
          value={selectedCommand}
          onChange={(e) => setCommand(e)}
          placeholder="Choose your action"
        />
      </div>

    </form>
  }

  const newAction = async () => {
    setLoaders({
      newAction: true
    })

    if (actionType === 'action') {
      if (!selectedActionId && newActionName === '') {
        alert('Action name is required')
      } else {
        await firestore.collection('signalActions').add({
          actionName: selectedActionId || newActionName,
          signalId: selectedSignalId,
          strategyId: selectedStrategyId,
          exchange: "TBC",
          command: selectedCommand,
          state: false,
          createdAt: new Date(),
          userId: user.uid,
          botId
        })
      }
    } else if (actionType === 'editStrategy') {
      let obj = {}
      for (const property in editedAction) {
        if (editedAction[property]) {
          obj[property] = isNaN(parseFloat(editedAction[property])) ? editedAction[property] : parseFloat(editedAction[property])
        }
      }
      await firestore.collection('signalActions').add({
        actionName: selectedActionId || newActionName,
        signalId: selectedSignalId,
        strategyId: selectedStrategyId,
        state: false,
        command: 'updateStrategy',
        createdAt: new Date(),
        strategyParameters: JSON.stringify(obj),
        botId,
        userId: user.uid,
      })
    }
    setLoaders({
      newAction: false
    })
    setModal(null)
  }

  const newEditStrategyForm = () => {
    return <form>
      <div className="form-group p-4">
        <label className="font-weight-bold">Choose a strategy</label>
        <SelectSearch
          options={strategies.map(s => ({
            name: `${s.symbol} ${s.positionSide}`,
            value: s.id
          }))}
          value={selectedStrategyId}
          onChange={(e) => {
            console.log(e)
            setStrategyId(e)
          }}
          search
          filterOptions={fuzzySearch}
          name="userStrategies"
          placeholder="Your strategies" />
        <br />
        <label className="font-weight-bold">Change one or more parameters</label>
        <input type="number" value={editedAction['orderSize']} onChange={(e) => editAction({ ...editedAction, orderSize: e.target.value })} className="form-control" placeholder="Order Size" />
        <input type="number" value={editedAction['takeStep']} onChange={(e) => editAction({ ...editedAction, takeStep: e.target.value })} className="form-control" placeholder="Take Step" />
        <input type="number" value={editedAction['orderFactor']} onChange={(e) => editAction({ ...editedAction, orderFactor: e.target.value })} className="form-control" placeholder="Order Factor" />
        <input type="number" value={editedAction['takeStepLimit']} onChange={(e) => editAction({ ...editedAction, takeStepLimit: e.target.value })} className="form-control" placeholder="Take Step Limit" />
        <input type="number" value={editedAction['stepFactor']} onChange={(e) => editAction({ ...editedAction, stepFactor: e.target.value })} className="form-control" placeholder="Step Factor" />
        <input type="number" value={editedAction['gridOrders']} onChange={(e) => editAction({ ...editedAction, gridOrders: e.target.value })} className="form-control" placeholder="Grid Orders" />
        <input type="number" value={editedAction['gridStep']} onChange={(e) => editAction({ ...editedAction, gridStep: e.target.value })} className="form-control" placeholder="Grid Step" />
        <select value={editedAction['positionSide']} onChange={(e) => editAction({ ...editedAction, positionSide: e.target.value })} className="form-control">
          <option value="">Set strategy type</option>
          <option value="SHORT">Short</option>
          <option value="LONG">Long</option>
        </select>
        <input type="text" value={editedAction['note']} onChange={(e) => editAction({ ...editedAction, note: e.target.value })} className="form-control" placeholder="Description" />
        <input type="number" value={editedAction['stopLossOffset']} onChange={(e) => editAction({ ...editedAction, stopLossOffset: e.target.value })} className="form-control" placeholder="Stop-loss offset" />
      </div>
    </form>
  }

  const changeTriggerState = (id, newStatus) => {
    console.log(id, newStatus)
    firestore.doc(`signalActions/${id}`).set({
      state: newStatus
    }, {
      merge: true
    })
  }

  return <div className="content">
    {
      user ?
        <div>
          <div className='row'>
            <div className='col-5 mx-auto text-center'>

              {
                actions.length > 0 && <>
                  <h3>Select a trigger &nbsp;</h3>
                  <SelectSearch
                    options={actions}
                    value={selectedActionId}
                    onChange={(e) => {
                      console.log(e)
                      setSignalId(apiActions.filter(a => a.actionName === e)[0]?.signalId)
                      setActionId(e)
                      setSection('list')
                    }}
                    search
                    filterOptions={fuzzySearch}
                    name="userAction"
                    placeholder="Choose your trigger" />
                  <h3 className='mb-2'>&nbsp; or &nbsp;</h3>
                </>
              }
              <Button color='succcess' className='d-inline' onClick={() => setSection('newAction')}>CREATE A NEW TRIGGER</Button>
            </div>
          </div>
          <hr className="w-75 mx-auto"></hr>
          <div className='row text-center'>
            {
              section !== 'newAction' && selectedActionId && selectedSignalId && <button type="button"
                onClick={() => { setModal('createAction') }}
                className="btn btn-social btn-round bg-success btn-outline mx-auto">
                Add action &nbsp;<i className="nc-icon nc-simple-add"></i>
              </button>
            }
          </div>
          {/* USER SIGNAL ACTIONS */}
          {
            section !== 'newAction' && apiActions.sort((a, b) => {
              if (a.createdAt > b.createdAt)
                return 1;
              else
                return -1
            }).map((action, id) => action.actionName === selectedActionId && <Row key={id}>
              <Col className="ml-auto mr-auto" lg="6" md="8" sm="12">
                <Card className="border border-primary">

                  {action.state === true ? <div
                    className="active-signal"
                    role="button"
                    onClick={(e) => changeTriggerState(action.id, !action.state)}
                  >{action.state}</div> : <div
                    className="inactive-signal"
                    role="button"
                    onClick={(e) => changeTriggerState(action.id, !action.state)}
                  >{action.state}</div>
                  }
                  {
                    action.strategyDetails ? <CardHeader>
                      <h5 className="header text-center mb-0">
                        &nbsp; {action.command === 'updateStrategy' ?
                          `CHANGE ${action.strategyDetails.symbol} ${action.strategyDetails.positionSide}` :
                          `${action.command?.toUpperCase()} on ${action.strategyDetails.symbol} - ${action.strategyDetails.positionSide}`}
                      </h5>
                    </CardHeader> : <CardHeader>
                      <h5 className="header text-center mb-0 text-danger">
                        <b>missing strategy {action.strategyId},<br />delete this trigger</b>
                      </h5>
                    </CardHeader>
                  }

                  <CardBody>
                    <FormGroup className="text-center">
                      {
                        action.command === 'updateStrategy' && <div>
                          {Object.entries(JSON.parse(action.strategyParameters)).map(([key, value]) => {
                            return (
                              <div>
                                {key}: {value}
                              </div>
                            );
                          })}
                        </div>
                      }
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <div className="row  text-center">
                      <div className="col-6 mx-auto">
                        <NavLink
                          to="/sign-in"
                          className="btn btn-round btn-block btn-danger font-weight-bold"
                          onClick={() => deleteAction(action.id)}
                        >
                          {loaders['deleteAction'] === true ? <Spinner size="sm" color="warning" /> : 'DELETE'}
                        </NavLink>
                      </div>
                      <div className="col-12 mx-auto text-center">
                        {/*<Button
                          block
                          className="btn-round text-success"
                          color="light"
                        >
                          Edit
                      </Button>*/}
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>)
          }
          {
            section === 'newAction' && <div className='text-center'>
              <h3>Create new action</h3>
              <div className='row justify-content-md-center'>
                <div className='col-lg-6 col-10'>
                  <FormGroup className='text-left'>
                    <label>Choose a signal from TradingView</label>
                    <SelectSearch
                      options={signals}
                      onChange={(e) => handlePairChange(e)}
                      search
                      filterOptions={fuzzySearch}
                      name="Pair"
                      placeholder="TradingView signals" />
                  </FormGroup>
                </div>
              </div>

              <div className='row justify-content-md-center'>
                <div className='col-lg-6 col-10'>
                  <FormGroup className='text-left'>
                    <label>Give a name to this trigger</label>
                    <Input
                      type={'text'}
                      onChange={(e) => {
                        setActionId(null)
                        setNewActionName(e.target.value)
                      }}
                      placeholder='Choose a name for this action' />
                  </FormGroup>
                </div>
              </div>

              <div className='row justify-content-md-center'>
                <div className='col-lg-6 col-10'>
                  <FormGroup className='text-left'>
                    <p>Choose the type of trigger</p>
                  </FormGroup>
                </div>
              </div>

              <div className="row justify-content-md-center">
                <div className="col-lg-3 col-6">
                  <input type="radio" value="action" name="actionType"
                    checked={actionType === 'action'}
                    onChange={handleOptionChange} /> Perform an action
                </div>
                <div className="col-lg-3 col-6">
                  <input type="radio" value="editStrategy" name="actionType"
                    checked={actionType === 'editStrategy'}
                    onChange={handleOptionChange} /> Edit strategy
                </div>
              </div>
              <div className='row justify-content-md-center'>
                <div className='col-lg-6 col-12'>
                  {
                    actionType === 'action' ?
                      newActionForm()
                      :
                      newEditStrategyForm()
                  }
                </div>
              </div>
              <Button color='primary' onClick={() => newAction()}>
                {loaders['newAction'] === true ? <Spinner size="sm" color="white" /> : 'CREATE'}
              </Button>
            </div>
          }


          <NotificationAlert ref={notifyRef} zIndex={9999} onClick={() => console.log("hey")} />
        </div>
        :
        <div></div>
    }
    <SweetAlert
      style={{ display: "block", marginTop: "-100px", overflow: 'visible' }}
      title="Create a new action"
      show={modal === 'createAction'}
      showCancel={true}
      confirmBtnText={'Create'}
      onCancel={hideAlert}
      onConfirm={newAction}
    >
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <input type="radio" value="action" name="actionType"
              checked={actionType === 'action'}
              onChange={handleOptionChange} /> Action
          </div>
          <div className="col">
            <input type="radio" value="editStrategy" name="actionType"
              checked={actionType === 'editStrategy'}
              onChange={handleOptionChange} /> Edit strategy
          </div>
        </div>
        {
          actionType === 'action' ?
            newActionForm()
            :
            newEditStrategyForm()
        }

      </div>
    </SweetAlert>
  </div >
}

export default Signals;