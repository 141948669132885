import React, { useState, useContext, useEffect } from "react";
import MyEnv from '../../environment.js';
import { auth, firestore } from '../../firebase'
import { UserContext } from '../../providers/UserProvider'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  Container,
  Col,
  Row,
  Spinner
} from "reactstrap";
import { NavLink } from "react-router-dom";

function Signin(props) {
  const user = useContext(UserContext);
  console.log('User', user)
  if (user !== null) {
    window.location.href = "/user/home"
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [ref, setRef] = useState(null)

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'userEmail') {
      setEmail(value);
    }
    else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      signInWithEmailAndPasswordHandler(e, email, password)
    }
  }

  useEffect(() => {
    let script3 = document.createElement("script");
    script3.src = "https://threejs.org/examples/js/libs/stats.min.js";
    script3.async = false;
    document.body.appendChild(script3);

    let script2 = document.createElement("script");
    script2.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
    script2.async = false;
    document.body.appendChild(script2);


    let script = document.createElement("script");
    script.src = "js/particles.js";
    script.async = false;
    document.body.appendChild(script);

    const params = new URLSearchParams(props.location.search);
    const ref = params.get('ref');
    console.log('ref', ref)
    if (ref)
      setRef(ref)

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    }
  }, []);


  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    setLoading(true)
    event.preventDefault();
    try {
      const loginResult = await auth.signInWithEmailAndPassword(email, password);
      setLoading(false)
      console.log(loginResult)
      debugger
      const userRef = firestore.collection('users').doc(loginResult.user.uid);
      const userDetail = await userRef.get()
      const userData = userDetail.data()
      const tags = userData.tags
    } catch (error) {
      setLoading(false)
      setErrorMessage("Error signing in with password and email!");
      console.error("Error signing in with password and email", error);
      if (error.code === 'auth/user-not-found') {
        setErrorMessage('User not found, are you registered?')
      } else if (error.code === 'auth/invalid-email') {
        setErrorMessage('Invalid email')
      }
    }

  };

  return (

    <div className="login-page">
      <div id="particles-js"></div>
      <Container className="mt-4">
        <Row className="align-items-center mt-4">
          <Col className="ml-auto mr-auto m-4" lg="6" md="9" sm="10">
            <img src="./facebook.jpg"></img>
            <Card className="card-login bg-success">
              <CardHeader>
                <CardHeader>
                  <h3 className="header text-center text-white">Welcome on TerraBot!</h3>
                </CardHeader>
              </CardHeader>
              <CardBody>
                <FormGroup className="text-center">
                  <FormGroup>
                    <Label style={{ width: '90%' }}>
                      <Input
                        type="text"
                        placeholder="Your e-mail"
                        name="userEmail"
                        value={email}
                        onChange={(event) => onChangeHandler(event)}
                      />
                      <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </FormGroup>

                <FormGroup className="text-center">
                  <FormGroup>
                    <Label style={{ width: '90%' }}>
                      <Input
                        type="password"
                        name="userPassword"
                        placeholder="Your password"
                        value={password}
                        onChange={(event) => onChangeHandler(event)}
                        onKeyDown={(e) => _handleKeyDown(e)}
                      />
                    </Label>
                  </FormGroup>
                </FormGroup>

                <FormGroup className="text-center">
                  <span className="text-danger font-weight-bold">{errorMessage}</span>
                  <FormGroup>
                    <Label>
                      <NavLink to="/reset-password" className="text-white font-weight-bold">Forgot password?</NavLink>
                    </Label>
                  </FormGroup>
                </FormGroup>


              </CardBody>
              <CardFooter>
                <div className="row">
                  <div className="col-6 mx-auto">
                    <Button
                      block
                      className="btn-round mb-3"
                      color="light"
                      onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password) }}
                    >
                      {!loading ? 'Sign in!' : <Spinner size="sm" color="primary" />}
                    </Button>
                    <NavLink
                      to={`/sign-up${ref ? `?ref=${ref}` : ''}`}
                      className="btn btn-round mb-3 btn-block btn-primary">
                        Sign-up
                    </NavLink>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <script src="/js/particles.js" type="text/javascript"></script>
      <title>TerraBot</title>
    </div>
  );
}

export default Signin;
