import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Spinner
} from 'reactstrap';
import Switch from "react-switch";
import { WithOutContext as ReactTags } from 'react-tag-input-no-dnd';
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import axios from 'axios';
import { UserContext } from "../../../providers/UserProvider";
import { firestore } from '../../../firebase'
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import 'assets/css/react-select-search.css'
import NotificationAlert from 'react-notification-alert';
import ApiService from 'components/ApiService';

const KeyCodes = {
    comma: 188,
    enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const EditStrategy = (props) => {
    const notifyRef = useRef(null);
    const apiService = new ApiService()

    const strategy = props.strategy

    const [userStra, setUserStrategies] = useState(props.strategies);

    useEffect(() => {
        console.log(props.strategies)
        setUserStrategies(props.strategies);
    }, [props.strategies]);

    const user = useContext(UserContext);

    const [account, setAccount] = useState(0)

    const [exchangeModes] = useState(['Binance Future', 'Binance Spot'])
    const [selectedBotType, setBotType] = useState(exchangeModes[0])

    const [pairs, setPairs] = useState([])
    const [selectedPair, setPair] = useState(strategy.symbol)

    const [strategies] = useState(['SHORT', 'LONG'])
    const [selectedStrategy, setStrategy] = useState(strategy.positionSide)

    const [geometricTypes] = useState(['Multiplier', 'Ultrafactor'])
    const [selectedGeometricType, setGeometricType] = useState(geometricTypes[0])

    const [orderBaseTypes] = useState(['Wallet %', 'Fixed amount'])
    const [selectedOrderBaseType, setOrderBaseType] = useState(strategy.orderBaseType === 'fix' ? 'Fixed amount' : 'Wallet %')

    const [orderTypes] = useState(['Multiplier', 'Linear'])
    const [selectedOrderType, setOrderType] = useState(orderTypes[0])


    const Exchange = exchangeModes.map(BotType => BotType)
    const Pair = pairs.map(pair => pair)
    const Strategy = strategies.map(strategy => strategy)
    const GeometricType = geometricTypes.map(geometricType => geometricType)
    const OrderBaseType = orderBaseTypes.map(orderBaseType => orderBaseType)
    const OrderType = orderTypes.map(orderType => orderType)

    const [currentGridOrders, setGridOrders] = useState(strategy.gridOrders)
    const [currentOrderStep, setOrderStep] = useState(strategy.gridStep)
    const [currentGeometricFactor, setGeometricFactor] = useState(strategy.stepFactor)
    const [currentOrderSize, setOrderSize] = useState(strategy.orderSize)
    const [currentOrderFactor, setOrderFactor] = useState(strategy.orderFactor)

    const [currentTakeStep, setTakeStep] = useState(strategy.takeStep)
    const [currentTakeStepLimit, setTakeStepLimit] = useState(strategy.takeStepLimit)
    const [currentTakeStepLimitThreshold, setTakeStepLimitThreshold] = useState(strategy.takeStepLimitThreshold)

    const [currentTrailingStop, setTrailingStop] = useState(strategy.callBack !== 0)
    const [currentStopLoss, setStopLoss] = useState(strategy.stopLossOffset !== 0)

    const [currentStopLossPercentage, setStopLossPercentage] = useState(strategy.stopLossOffset)
    const [currentCallbackRate, setCallbackRate] = useState(strategy.callBack)

    const [tags, setTags] = useState(strategy.tags.map(item => ({ id: item, text: item })))
    const [userTags, setUserStags] = useState([])
    const [newUserTags, setNewUserTags] = useState([])

    const [suggestions, setSuggestions] = useState([])

    const [showSuccessModal, setSuccesModal] = useState(false)

    const [loading, setLoading] = useState(false)

    const [currentNote, setNote] = useState(strategy.note)

    const [currentApiPublic, setApiPublic] = useState(strategy.apiPublic)

    const handleExchangeTypeChange = (e) => {
        setBotType(exchangeModes[e.target.value])
    }

    const handlePairChange = (e) => {
        setPair(e)
    }

    const handleStrategyChange = (e) => {
        setStrategy(e)
    }

    const handleGeometricTypeChange = (e) => {
        setGeometricType(geometricTypes[e.target.value])
    }

    const handleOrderBaseTypeChange = (e) => {
        setOrderBaseType(orderBaseTypes[e.target.value])
        if (orderBaseTypes[e.target.value] === 'Fixed amount') {
            setOrderSize(5)
        } else {
            setOrderSize(1)
        }
    }

    const handleOrderTypeChange = (e) => {
        setOrderType(orderTypes[e.target.value])
    }

    const handleAddition = (tag) => {
        console.log(userTags.indexOf(tag.text))
        if (userTags.indexOf(tag.text) === -1) {
            setNewUserTags([...newUserTags, tag.text])
        }
        setTags([...tags, tag])
    }

    const handleDelete = (i) => {
        setTags([...tags.filter((tag, index) => index !== i)])
    }

    const notification = (obj) => {
        notifyRef.current.notificationAlert({
            place: 'bc',
            message: (
                <div>
                    <div>
                        <b>{obj.text}</b>
                    </div>
                </div>
            ),
            type: obj.type,
            autoDismiss: 2
        });
    }

    const fetchSymbols = useCallback(async () => {
        if (user) {
            console.log(pairs)
            console.log(user)
            let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
            let bots = localStorage.getItem('fullBots') ? JSON.parse(localStorage.getItem('fullBots')) : []
            const records = Buffer.from(storage.account, 'base64').toString()
            const bot = bots.filter(bot => bot.id === records)[0]
            const symbols = await apiService.getSymbols(
                bot.exchangeType,
                user
            )
            console.log(symbols)
            const pairs = symbols.sort().map(pair => ({ name: pair, value: pair }))
            setPairs(pairs)
        }
    }, [user])

    useEffect(() => {
        if (user === null) {
            console.log('')
        } else {
            fetchSymbols()
            setPair(props.strategy.symbol)

            let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
            if (storage.account) {
                setAccount(atob(storage.account))
            }
            firestore
                .doc(`users/${user.uid}`)
                .get()
                .then((data) => {
                    let obj = data.data()
                    let tags = []
                    obj.tags.forEach((doc) => {
                        tags.push({ id: doc, text: doc })
                    })
                    const onlyValues = tags.map(tag => tag.text)
                    setSuggestions(tags)
                    setUserStags(onlyValues)
                })
        }
    }, [user]);

    const fuzzySearch = (options) => {
        const fuse = new Fuse(options, {
            keys: ['name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }

            return fuse.search(value);
        };
    }

    const findSameStrategy = (exchange, symbol, side) => {
        console.log(exchange, ' ', symbol, ' ', side)
        let passedCheck = true
        for (let i = 0; i < userStra.length; i++) {
            console.log(`${userStra[i].exchange}, ${exchange}`)
            console.log(`${userStra[i].symbol}, ${symbol}`)
            console.log(`${userStra[i].positionSide}, ${side}`)
            if (
                userStra[i].exchange === exchange &&
                userStra[i].symbol === symbol &&
                userStra[i].positionSide === side
            ) {
                passedCheck = false
            }
        }
        return passedCheck
    }

    const checkSameCoinStrategy = (symbol) => {
        let passedCheck = true
        for (let i = 0; i < userStra.length; i++) {
            console.log(`${userStra[i].symbol}, ${symbol}`)
            if (
                userStra[i].symbol === symbol &&
                userStra[i].exchange === 'okexm'
            ) {
                passedCheck = false
            }
        }
        return passedCheck
    }

    const updateStrategy = () => {
        setLoading(true)
        let stratTags = tags.map(tags => tags.text)

        if (props.mode === 'edit') {
            firestore.doc(`strategies/${strategy.id}`).set({
                botId: account,
                symbol: selectedPair,
                exchange: strategy.exchange,
                typeStrategy: selectedStrategy.toLowerCase(),
                typeGrid: "standard",
                gridOrders: currentGridOrders,
                gridStep: currentOrderStep,
                stepType: selectedGeometricType,
                stepFactor: currentGeometricFactor,
                orderBaseType: selectedOrderBaseType === "Wallet %" ? 'percentage' : 'fix',
                orderSize: currentOrderSize,
                orderType: selectedOrderType,
                orderFactor: currentOrderFactor,
                positionSide: selectedStrategy,

                takeStep: currentTakeStep,
                takeStepLimit: currentTakeStepLimit,
                takeStepLimitThreshold: currentTakeStepLimitThreshold,

                callBack: currentCallbackRate,
                stopLossOffset: currentStopLoss === true ? currentStopLossPercentage : 0,

                tags: stratTags,

                apiPublic: currentApiPublic,

                note: currentNote,

                userId: user.uid
            }, { merge: true })
                .then(async () => {
                    const newTagsArray = [...userTags, ...newUserTags]
                    firestore.collection("users").doc(user.uid).set({
                        tags: newTagsArray
                    }, {
                        merge: true
                    }).then(() => {
                        // reload strategy on worker
                        apiService.operation(
                            'reload',
                            [strategy.id],
                            {
                                headers: {
                                    'authorization': `Bearer ${user.Aa}`
                                }
                            }
                        )
                        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
                        storage.tags = newTagsArray
                        localStorage.setItem('preferences', JSON.stringify(storage))
                    })
                })
                .catch(e => {
                    console.log(e)
                })
                .then(() => {
                    setLoading(false)
                    props.hideAlert()
                })
        } else if (props.mode === 'duplicate') {
            if (strategy.exchange === 'binancef')
                if (!findSameStrategy("binancef", selectedPair, selectedStrategy)) {
                    setLoading(false)
                    notification({
                        type: 'danger',
                        text: 'You cannot have different strategies with the same: Strategy (short/long), Pair and Exchange!'
                    })
                    return -1
                }
            if (strategy.exchange === 'okexm') {
                // check okex constraint
                if (!checkSameCoinStrategy(selectedPair)) {
                    setLoading(false)
                    notification({
                        type: 'danger',
                        text: 'You cannot have different strategies with the same pair'
                    })
                    return -1
                }
            }
            firestore.collection(`strategies`).add({
                botId: account,
                symbol: selectedPair,
                exchange: strategy.exchange,
                typeStrategy: selectedStrategy.toLowerCase(),
                typeGrid: "standard",
                gridOrders: currentGridOrders,
                gridStep: currentOrderStep,
                stepType: selectedGeometricType,
                stepFactor: currentGeometricFactor,
                orderBaseType: selectedOrderBaseType === "Wallet %" ? 'percentage' : 'fix',
                orderSize: currentOrderSize,
                orderType: selectedOrderType,
                orderFactor: currentOrderFactor,
                positionSide: selectedStrategy,

                status: '',

                takeStep: currentTakeStep,
                takeStepLimit: currentTakeStepLimit,
                takeStepLimitThreshold: currentTakeStepLimitThreshold,

                callBack: currentCallbackRate,
                stopLossOffset: currentStopLoss === true ? currentStopLossPercentage : 0,

                tags: stratTags,

                note: currentNote,

                apiPublic: currentApiPublic,

                userId: user.uid
            })
                .then(async () => {
                    console.log('Updated document')
                    const newTagsArray = [...userTags, ...newUserTags]
                    firestore.collection("users").doc(user.uid).set({
                        tags: newTagsArray
                    }, {
                        merge: true
                    }).then(() => {
                        // reload strategy on worker
                        apiService.operation(
                            'reload',
                            [strategy.id],
                            {
                                headers: {
                                    'authorization': `Bearer ${user.Aa}`
                                }
                            }
                        )
                        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
                        storage.tags = newTagsArray
                        localStorage.setItem('preferences', JSON.stringify(storage))
                    })
                })
                .catch(e => {
                    console.log(e)
                })
                .then(() => {
                    setLoading(false)
                    props.hideAlert()
                })
        }
    }

    return <div>
        <h6 className="pt-0 mt-1">GENERAL SECTION</h6>
        <div className="row">
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Exchange</label>
                    <p className="border border-primary p-2 font-weight-normal">{strategy.exchange}</p>
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Select Pair</label>
                    {
                        props.mode === 'edit' ?
                            <p className="border border-primary p-2 font-weight-normal">{strategy.symbol}</p> :
                            <SelectSearch
                                options={pairs}
                                onChange={(e) => handlePairChange(e)}
                                search
                                value={selectedPair}
                                filterOptions={fuzzySearch} name="Pair"
                            />
                    }
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="form-group">
                    <label>Strategy</label>
                    {
                        props.mode === 'edit' ?
                            <p className="border border-primary p-2 font-weight-normal">{strategy.positionSide.toUpperCase()}</p> :
                            <select
                                className="form-control"
                                onChange={e => handleStrategyChange(e.target.value)}
                                value={selectedStrategy}
                            >
                                {
                                    Strategy.map((type, key) => <option
                                        key={key}
                                        value={type}
                                    >
                                        {type} {selectedStrategy === type}
                                    </option>)
                                }
                            </select>
                    }

                </div>
            </div>
        </div>

        <h6 className="pt-0 mt-1">GRID SECTION</h6>
        <hr className="w-75 ml-0" />
        <div className="row">
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Grid Orders</label>
                    <input
                        type="number"
                        className="form-control"
                        value={currentGridOrders}
                        min={1}
                        onChange={(event) => setGridOrders(parseInt(event.target.value))}
                    />
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>1° Order Step (%)</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        value={currentOrderStep}
                        min={0}
                        onChange={(event) => setOrderStep(parseFloat(event.target.value))}
                    />
                </div>
            </div>

            <div className="col-6 col-md-3">
                <div className="form-group">
                    <label>Step Type</label>
                    <select
                        className="form-control"
                        value={geometricTypes[0]}
                        onChange={e => handleGeometricTypeChange(e)}
                        disabled={true}
                    >
                        {
                            GeometricType.map((type, key) => <option key={key} value={key}>{type}</option>)
                        }
                    </select>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="form-group">
                    <label>Step Factor</label>
                    <input
                        type="number"
                        className="form-control"
                        value={currentGeometricFactor}
                        step={0.01}
                        min={0}
                        onChange={(event) => setGeometricFactor(parseFloat(event.target.value))}
                    />
                </div>
            </div>
        </div>

        <h6 className="pt-0 mt-1">ORDER SECTION</h6>
        <hr className="w-75 ml-0" />
        <div className="row">
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Order Base Type</label>
                    <select
                        className="form-control"
                        onChange={e => handleOrderBaseTypeChange(e)}
                    >
                        {
                            OrderBaseType.map((type, key) => <option
                                key={key}
                                value={key}
                                selected={selectedOrderBaseType === type}
                            >
                                {type}
                            </option>)
                        }
                    </select>
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Order Size {selectedOrderBaseType === 'Fixed amount' ? '' : '(%)'}</label>
                    <input
                        className="form-control"
                        type="number"
                        value={currentOrderSize}
                        step={0.1}
                        min={selectedOrderBaseType === 'Wallet %' ? 0.1 : 5}
                        onChange={(event) => {
                            const value = parseFloat(event.target.value.replace(',', '.'))
                            setOrderSize(value)
                        }}
                    />
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Order Type</label>
                    <select
                        className="form-control"
                        onChange={e => handleOrderTypeChange(e)}
                        value={orderTypes[0]}
                        disabled={true}
                    >
                        {
                            OrderType.map((type, key) => <option key={key} value={key}>{type}</option>)
                        }
                    </select>
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Order Factor</label>
                    <input
                        type="number"
                        className="form-control"
                        value={currentOrderFactor}
                        onChange={(event) => setOrderFactor(parseFloat(event.target.value))}
                    />
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Take Step (%)</label>
                    <input
                        type="number"
                        className="form-control"
                        min={0}
                        value={currentTakeStep}
                        onChange={(event) => setTakeStep(parseFloat(event.target.value))}
                    />
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Take step limit (%)</label>
                    <input
                        type="number"
                        className="form-control"
                        value={currentTakeStepLimit}
                        min={0}
                        step={0.01}
                        onChange={(event) => setTakeStepLimit(parseFloat(event.target.value))}
                    />
                </div>
            </div>
            <div className="col-md-3 col-6">

                <div className="form-group">
                    <label>Stop loss</label><br />
                    <Switch
                        onChange={e => setStopLoss(!currentStopLoss)}
                        checked={currentStopLoss}
                        className="react-switch"
                        onChange={e => {
                            setStopLoss(e)
                            if (e)
                                setStopLossPercentage(0.1)
                            else
                                setStopLossPercentage(0)
                        }}
                    />
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Stop loss (%)</label>
                    <input
                        type="number"
                        className="form-control"
                        value={currentStopLossPercentage}
                        min={0.1}
                        step={0.01}
                        disabled={!currentStopLoss}
                        onChange={(event) => setStopLossPercentage(parseFloat(event.target.value))}
                    />
                </div>
            </div>
            {/*
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Take Step Limit Threshold</label>
                    <input
                        type="number"
                        className="form-control"
                        step={0}
                        value={currentTakeStepLimitThreshold}
                        onChange={(event) => setTakeStepLimitThreshold(parseInt(event.target.value))}
                    />
                </div>
            </div>
            */}
        </div>

        <div className="row">
            {/*
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Trailing Stop</label><br />
                    <Switch
                        onChange={e => {
                            setTrailingStop(e)
                            if (e)
                                setCallbackRate(0.1)
                            else
                                setCallbackRate(0)
                        }}
                        checked={currentTrailingStop}
                        className="react-switch"
                    />
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="form-group">
                    <label>Callback rate (%)</label>
                    <input
                        className="form-control"
                        type="number"
                        disabled={!currentTrailingStop}
                        value={currentCallbackRate}
                        min={0.1}
                        step={0.01}
                        onChange={(event) => setCallbackRate(parseFloat(event.target.value))}
                    />
                </div>
            </div>
            */}

        </div>

        <div className="row">
            <div className="col-6">
                <label>API service</label><br />
                <label className="font-weight-normal">{currentApiPublic ? <pre className="mb-0">Strategy ID {props.strategy.id}</pre> : ''}</label><br />
                <Switch
                    onChange={e => setApiPublic(!currentApiPublic)}
                    checked={currentApiPublic}
                />
            </div>
            <div className="col-6">
                <h6 className="mt-2 mb-2">STRATEGY TAGS</h6>
                <ReactTags tags={tags}
                    suggestions={suggestions}
                    handleAddition={handleAddition}
                    handleDelete={handleDelete}
                    inputFieldPosition="inline"
                    allowDeleteFromEmptyInput={false}
                    placeholder="Type something.."
                    delimiters={delimiters}
                    minQueryLength={1}
                    classNames={{
                        tagInput: "w-100",
                        tagInputField: "rounded w-100"
                    }}
                />
            </div>
        </div>

        <div className="row">
            <div className="form-group col-12">
                <div className="form-group">
                    <h6 className="mt-2 mb-2">Note</h6>
                    <textarea rows="1" className="form-control" value={currentNote} onChange={(e) => setNote(e.target.value)}></textarea>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="mx-auto">
                <ButtonGroup>
                    <Button
                        className="btn btn-secondary btn-lg"
                        onClick={props.hideAlert}
                    >Cancel</Button>
                    <Button
                        className="btn btn-primary btn-lg"
                        onClick={updateStrategy}
                    >
                        {!loading ? <span>{props.mode === 'edit' ? 'Update' : 'Duplicate'}</span> : <Spinner size="sm" color="primary" />}
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <NotificationAlert ref={notifyRef} zIndex={9999} onClick={() => console.log("hey")} />
    </div>
}

export default EditStrategy;