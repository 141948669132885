import React, { useState, useRef, useEffect, useContext } from 'react';
import MyEnv from '../../../environment';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Spinner
} from 'reactstrap';
import { firestore, auth } from '../../../firebase';
import { UserContext } from "../../../providers/UserProvider";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ApiContext } from '../../../providers/ApiProvider';
import axios from 'axios'
import './profile.css'
import "react-notification-alert/dist/animate.css";
import NotificationAlert from 'react-notification-alert';

const Profile = () => {
    const notifyRef = useRef(null);

    const [botTypes] = useState(['Binance Future', 'Binance Spot'])
    const [selectedBotType, setBotType] = useState('Binance Future')
    const [apiKey, setApiKey] = useState('')
    const [apiSecret, setApiSecret] = useState('')
    const [botName, setBotName] = useState('')

    const [passwordOne, setPasswordOne] = useState('')
    const [passwordTwo, setPasswordTwo] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')

    const [telegramChatId, setTelegramChatId] = useState('')
    const [apiToken, setApiToken] = useState('')
    const [resultModal, setModal] = useState('')

    const [loading, setLoading] = useState(false)

    const user = useContext(UserContext);
    const api = useContext(ApiContext);

    const [currentBots, setCurrentBots] = useState(null)

    const BotType = botTypes.map(BotType => BotType)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorOnTelegramChatId, setErrorOnTelegramChatId] = useState('')

    const [itemEditing, setItemEditing] = useState('')
    const [currentModal, setCurrentModal] = useState('')

    const notification = (obj) => {
        notifyRef.current.notificationAlert({
            place: 'bc',
            message: (
                <div>
                    <div>
                        <b>{obj.text}</b>
                    </div>
                </div>
            ),
            type: obj.type,
            autoDismiss: 2
        });
    }

    const generateToken = (length, onlyLowercaseLetters = false) => {
        //edit the token allowed characters
        let a = ''
        if (onlyLowercaseLetters)
            a = "abcdefghijklmnopqrstuvwxyz".split("");
        else
            a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        let b = [];
        for (let i = 0; i < length; i++) {
            let j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    useEffect(() => {
        console.log('init config page')
        auth.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                const userRef = firestore.collection('users').doc(userAuth.uid);
                const userDetail = await userRef.get()
                const telegramChatIds = userDetail.data().telegramChatIds
                const apiToken = userDetail.data().extAuthToken

                setTelegramChatId(telegramChatIds)
                setApiToken(apiToken)

                /*firestore
                    .collection('bots')
                    .where('userId', '==', userAuth.uid)
                    .onSnapshot((snapshot) => {
                        const accounts = []
                        snapshot.docs.forEach(account => {
                            let accountObj = { id: account.id, ...account.data(), editDisabled: true }
                            accounts.push(accountObj)
                        })
                        setCurrentBots(accounts)
                    });*/
            }
        })
    }, []);

    const handleAddrTypeChange = (e) => {
        setBotType(botTypes[e.target.value])
    }

    const newBotValidation = () => {
        if (
            apiKey === '' ||
            apiSecret === '' ||
            botName === ''
        ) {
            setErrorMessage('Some fields are missing..')
            return false
        }
        return true
    }

    const resetPassword = async () => {
        auth.sendPasswordResetEmail(user.providerData[0].uid)
            .then(function () {
                console.log('email sent')
                notification({
                    type: 'success',
                    text: 'You will receive a password reset link by email'
                })
            })
            .catch(function (error) {
                console.log('error on email')
                notification({
                    type: 'error',
                    text: 'Could not send reset email, contact administrators on Telegram!'
                })
            });
    }

    const handleNewBotSubmit = async () => {
        if (newBotValidation()) {
            try {
                setLoading(true)
                const id = generateToken(40, true)
                const doc = await firestore.doc(`bots/${id}`).set({
                    exchangeType: selectedBotType === 'Binance Future' ? 'binancef' : '',
                    alias: botName,
                    apiKey: apiKey,
                    status: "active",
                    userId: user.uid
                })
                notification({
                    type: 'success',
                    text: 'Your new bot has been successfully created!'
                })
                // todo fix cors
                const res = await axios.post(`https://api.terrabot.club/v1/bot/${id}/credential`,
                    {
                        "accessKey": apiKey,
                        "secretKey": apiSecret
                    }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.Aa
                    }
                })
                console.log(res)
                /*.then(async doc => {
                    await firestore.collection('botsCredentials').doc(doc.id)
                        .add({
                            accessKey: apiKey,
                            secretKey: apiSecret,
                            userId: user.uid,
                        }).then(() => {
                            notification({
                                type: 'success',
                                text: 'Your new bot has been successfully created!'
                            })
                        })
                })*/
                setLoading(false)
            } catch (e) {
                console.log(e)
                setLoading(false)
                notification({
                    type: 'danger',
                    text: 'Seems like someone is broken, contact administrators on Telegram!'
                })
            }
            setBotName("")
            setApiKey("")
            setApiSecret("")
            setBotType(botTypes[0])
        }
    };

    const handleSaveTelegramChatId = () => {
        setItemEditing('telegram')
        console.log('Update telegram id..')
        firestore.collection("users").doc(user.uid).set({
            telegramChatIds: telegramChatId
        }, { merge: true })
            .then(() => {
                console.log("Document successfully written!");
                notification({
                    type: 'success',
                    text: 'Telegram Chat ID updated succesfully'
                })
                setItemEditing('')
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
                notification({
                    type: 'error',
                    text: 'Could not update Telegram Chat ID'
                })
                setItemEditing('')
            });
        /*if (telegramChatId === '' || !parseInt(telegramChatId) || true ) {
        } else {
        }*/
    }

    const handleApiToken = (confirmed) => {
        if (confirmed) {
            setItemEditing('api-token')
            const newApiToken = generateToken(32, false)
            setApiToken(newApiToken)
            setCurrentModal('')
            firestore.collection("users").doc(user.uid).set({
                extAuthToken: newApiToken
            }, { merge: true })
                .then(() => {
                    console.log("Document successfully written!");
                    notification({
                        type: 'success',
                        text: 'API Token updated succesfully'
                    })
                    setItemEditing('')
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                    notification({
                        type: 'error',
                        text: 'Could not update API Token'
                    })
                    setItemEditing('')
                });
        } else {
            setCurrentModal('apiToken')
        }
    }

    return <div className="content">
        {resultModal}
        {
            user ?
                <div>
                    <div className="row">
                        <div className="col-md-3 mt-2">
                            <h3 className="mb-1 pt-3">Account password</h3>
                            <button
                                type="button"
                                className="btn btn-success w-100 btn-sm p-2"
                                onClick={() => resetPassword()}
                            >
                                Change
                            </button>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <h3 className="mb-1">Set Telegram chat ID</h3>
                            <h6 className="text-danger">
                                { /* {telegramChatId !== '' && !parseInt(telegramChatId) ? 'Chat ID must be a number!' : ""} */}
                            </h6>
                        </div>
                        <div className="col-md-6">
                            {/*<p className="mb-1">How to find this data? Follow our <a href="/guida" target="_blank">TUTORIAL</a></p>*/}
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={telegramChatId}
                                    placeholder="Example: 245452,9843584935"
                                    className="form-control input-group rounded-0"
                                    onChange={(event) => setTelegramChatId(event.target.value)}
                                />
                                <button
                                    className="btn btn-success input-group-append rounded-0"
                                    type="button"
                                    disabled={telegramChatId == ""/*telegramChatId == '' || !parseInt(telegramChatId) ? true : false*/}
                                    onClick={() => handleSaveTelegramChatId()}
                                >
                                    {itemEditing !== 'telegram' ?
                                        <span className="fa fa-floppy-o fa-lg pt-1" aria-hidden="true"></span>
                                        :
                                        <Spinner size="sm" color="primary" />}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <h3 className="mb-1">API Token</h3>
                            <h6 className="text-danger">
                                { /* {telegramChatId !== '' && !parseInt(telegramChatId) ? 'Chat ID must be a number!' : ""} */}
                            </h6>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={apiToken}
                                    className="form-control input-group rounded-0"
                                    onChange={(event) => setTelegramChatId(event.target.value)}
                                />
                                <button
                                    className="btn btn-success input-group-append rounded-0"
                                    type="button"
                                    disabled={telegramChatId == ""/*telegramChatId == '' || !parseInt(telegramChatId) ? true : false*/}
                                    onClick={() => handleApiToken()}
                                >
                                    {itemEditing !== 'api-token' ?
                                    <i className="fa fa-refresh fa-lg pt-1" aria-hidden="true"></i> 
                                    :
                                    <Spinner size="sm" color="primary" />}
                                </button>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                        type="warning"
                        show={currentModal === 'apiToken'}
                        showCancel={true}
                        title="New API Token"
                        confirmBtnText="Yes, proceed and block all other apps!"
                        cancelBtnText="No!"
                        onCancel={() => setCurrentModal('')}
                        onConfirm={() => handleApiToken(true)}
                    >
                        <div className="font-weight-bold">
                            Are you sure?
                        </div>
                    </SweetAlert>
                    <NotificationAlert ref={notifyRef} zIndex={9999} onClick={() => console.log("hey")} />
                </div>
                :
                <div>loading</div>
        }
    </div >
}

export default Profile;