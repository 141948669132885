
import UserStrategies from "views/user/userStrategies/userStrategies.js"
import ChooseStrategy from "views/user/chooseStrategy/chooseStrategy";
import NewCustomStrategy from "views/user/newCustomStrategy/newCustomStrategy";
import NewStandardStrategy from "views/user/newStandardStrategy/newStandardStrategy";
import NewBot from "views/user/newBot/newBot";
import Analytics from "views/user/analytics/analytics";
import SignUp from "views/pages/Signup";
import Profile from "views/user/profile/profile";
import Signals from "views/user/signals/signals";

const routes = [{
  path: "/",
  name: "Home",
  component: UserStrategies,
  redirect: true
}, {
  icon: "nc-icon nc-ruler-pencil",
  path: "/home",
  name: "Home",
  component: UserStrategies,
  layout: "/user",
},
/*
{
  icon: "nc-icon nc-briefcase-24",
  path: "/new-strategy",
  name: "Nuova strategia",
  component: ChooseStrategy,
  layout: "/user",
},*/

{
  icon: "nc-icon nc-chart-bar-32",
  path: "/analytics",
  component: Analytics,
  name: "Analytics",
  layout: "/user"
},

{
  icon: "nc-icon nc-simple-add",
  path: "/new-bot",
  component: NewBot,
  name: "Nuovo bot",
  layout: "/user"
}, {
  icon: "nc-icon nc-vector",
  path: "/signals",
  component: Signals,
  name: "Signals",
  layout: "/user"
},

{
  icon: "nc-icon nc-single-02",
  path: "/profile",
  component: Profile,
  name: "Profile",
  layout: "/user"
},

{
  icon: "nc-icon nc-single-02",
  path: "/standard",
  component: NewStandardStrategy,
  layout: "/user",
  redirect: true
}, {
  icon: "nc-icon nc-single-02",
  path: "/custom",
  component: NewCustomStrategy,
  layout: "/user",
  redirect: true
},

{
  icon: "nc-icon nc-single-02",
  path: "/sign-up",
  component: SignUp,
  redirect: true
}, {
  path: "/",
  component: UserStrategies,
  name: "Nuovo bot",
  layout: "/",
  redirect: true
}];

export default routes;
