import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import ArtistLayout from "layouts/Artist.js";
import UserProvider from './providers/UserProvider'
import ApiProvider from "providers/ApiProvider";
import Signin from "views/pages/Signin.js";
import Signup from "views/pages/Signup.js";
import Profile from "views/pages/Profile.js";
import Reset from "views/pages/Reset";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.7.0";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const hist = createBrowserHistory();

ReactDOM.render(
  <UserProvider>
    <ApiProvider>
      <Router history={hist}>
        <Switch>
          <Route path="/sign-in" exact={true} component={(props) => <Signin {...props}/>} />
          <Route path="/sign-up" exact={true} component={(props) => <Signup {...props} />} />
          <Route path="/reset-password" exact={true} component={Reset} />
          <Route path="/profile" exact={true} render={(props) => <Profile />} />
          <Route path="/" render={(props) => <ArtistLayout {...props} />} />
        </Switch>
      </Router>
    </ApiProvider>
  </UserProvider>,
  document.getElementById("root")
);
