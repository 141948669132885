import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Spinner
} from 'reactstrap';
import React from 'react';
import 'assets/css/react-select-search.css'

const KeyCodes = {
    comma: 188,
    enter: [10, 13],
};


const TakeProfits = (props) => {

    return <div className="text-dark text-left font-weight-normal">
        <h6 className="pt-4">Balance</h6>
        <table responsive bordered striped size="sm" className="risk-calculator mb-4">
            <thead>
                <tr>
                    <th className="text-center">Symbol</th>
                    <th className="text-center">amount</th>
                    <th className="text-center">Last loss %</th>
                    <th className="text-center">Last loss</th>
                    <th className="text-center">Leverage impact on wallet %</th>
                    <th className="text-center">Leverage impact on wallet</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.risk?.quote.map((quote, key) => {
                        return <tr key={key}>
                            <td className="text-center">{quote.quote}</td>
                            <td className="text-center">{quote.balance.toFixed(this.getFixedPositions(quote.quote))} {quote.quote}</td>
                            <td className="text-center">{quote.totalGridLastLossPerc.toFixed(this.getFixedPositions(quote.quote))}%</td>
                            <td className="text-center">{quote.totalGridLastLossValue.toFixed(this.getFixedPositions(quote.quote))} {quote.quote}</td>
                            <td className="text-center">{quote.totalWalletImpactLeveragePerc.toFixed(this.getFixedPositions(quote.quote))}%</td>
                            <td className="text-center">{quote.totalWalletImpactLeverageValue.toFixed(this.getFixedPositions(quote.quote))} {quote.quote}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>

        <h6 className="pt-4">Risk</h6>
        <table responsive bordered striped size="sm" className="risk-calculator mb-4">
            <thead>
                <tr>
                    <th className="text-center">Symbol</th>
                    <th className="text-center">Market Price</th>
                    <th className="text-center">First Step Size</th>
                    <th colSpan={2} className="text-center" style={{ backgroundColor: '#fdc44c7a' }}>Loss (stop loss)</th>
                    <th colSpan={3} className="text-center">Grid Exposure</th>
                    <th colSpan={6} className="text-center">Last Grid Risk</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="text-center">Grid Value</th>
                    <th className="text-center">Margin Value</th>
                    <th className="text-center">Margin %</th>
                    <th className="text-center">last price</th>
                    <th colSpan={2} className="text-center" style={{ backgroundColor: '#fdc44c7a' }}>loss</th>
                    <th className="text-center">entry price</th>
                    <th className="text-center">_ vs last price</th>
                    <th className="text-center">_ vs market price</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.risk?.risk.map((risk, key) => {
                        return <tr key={key}>
                            <td>{risk.symbol}<br />({risk.positionSide} {risk.leverage}x)</td>
                            <td>{risk.marketPrice.toFixed(this.getFixedPositions(risk.quoteAsset))} {risk.quoteAsset}</td>
                            <td>{risk.sizeFirstStep.toFixed(4)} {risk.quoteAsset}</td>
                            <td>{risk.lossStopLossValue.toFixed(4)}%</td>
                            <td>{risk.lossStopLossPerc.toFixed(4)}%</td>
                            <td>{risk.gridValue.toFixed(this.getFixedPositions(risk.quoteAsset))} {risk.quoteAsset}</td>
                            <td>{risk.marginUsedValue.toFixed(this.getFixedPositions(risk.quoteAsset))} {risk.quoteAsset}</td>
                            <td>{risk.marginUsedPercentage.toFixed(4)}%</td>
                            <td>{risk.gridLastPrice.toFixed(this.getFixedPositions(risk.quoteAsset))} {risk.quoteAsset}</td>
                            <td style={{ color: 'red', backgroundColor: '#fdc44c7a' }}>aaa{risk.gridLastLossValue.toFixed(this.getFixedPositions(risk.quoteAsset))} {risk.quoteAsset}</td>
                            <td style={{ backgroundColor: '#fdc44c7a' }}>{risk.gridLastLossPerc.toFixed(4)}%</td>
                            <td>{risk.gridLastEntryPrice.toFixed(this.getFixedPositions(risk.quoteAsset))} {risk.quoteAsset}</td>
                            <td>{risk.gridLastEntryVsGridLastPricePerc.toFixed(4)}%</td>
                            <td>{risk.gridLastEntryVsMarketPricePerc.toFixed(4)}%</td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    </div>
}

export default TakeProfits;