import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDvC1NXKM6eRmbX_p8Hm7-uuJEAxQyCZNM",
    authDomain: "tbtc-terrabot-prod.firebaseapp.com",
    projectId: "tbtc-terrabot-prod",
    storageBucket: "tbtc-terrabot-prod.appspot.com",
    messagingSenderId: "531021657649",
    appId: "1:531021657649:web:af7ad08d472a1d9afec710"
};

firebase.initializeApp(firebaseConfig);

export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        const { email, photoURL } = user;
        try {
            await userRef.set({
                email,
                photoURL,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
}

export const getTelegramChatIds = (userId) => {
    return firestore.doc(`users/${userId}`).chatIds
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
