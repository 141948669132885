import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Input,
    Container,
    Col,
    Row,
    Spinner
} from "reactstrap";
import { auth } from '../../firebase'
import { UserContext } from '../../providers/UserProvider'
import NotificationAlert from 'react-notification-alert';
import { NavLink } from "react-router-dom";

const Reset = () => {
    const notifyRef = useRef(null);

    const notification = (obj) => {
        notifyRef.current.notificationAlert({
            place: 'bc',
            message: (
                <div>
                    <div>
                        <b>{obj.text}</b>
                    </div>
                </div>
            ),
            type: obj.type,
            autoDismiss: 2
        });
    }

    useEffect(() => {
        let script3 = document.createElement("script");
        script3.src = "https://threejs.org/examples/js/libs/stats.min.js";
        script3.async = false;
        document.body.appendChild(script3);

        let script2 = document.createElement("script");
        script2.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
        script2.async = false;
        document.body.appendChild(script2);


        let script = document.createElement("script");
        script.src = "js/particles.js";
        script.async = false;
        document.body.appendChild(script);


        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
        }
    }, []);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
        }
    });

    const [userEmail, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)

    const user = useContext(UserContext);
    console.log('User', user)

    const resetPassword = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            auth.sendPasswordResetEmail(userEmail)
                .then(function () {
                    console.log('email sent')
                    notification({
                        type: 'success',
                        text: 'Your new bot has been successfully created!'
                    })
                })
                .catch(function (error) {
                    console.log(error)
                    console.log('error on email')
                    notification({
                        type: 'danger',
                        text: 'Could not send reset email, contact administrators on Telegram!'
                    })
                });
        }
        catch (error) {
            setLoading(false)
            console.log('Error', error)
            setError('Error signing up. Check your mail address!');
        }
        setLoading(false)
        setEmail("");
    }

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        if (name === "userEmail") {
            setEmail(value);
        }
    };

    return (
        <div className="pt-2">
            <div id="particles-js"></div>
            <Container style={{ marginTop: '100px' }}>

                <Row>
                    <Col className="ml-auto mr-auto" lg="6" md="9" sm="10">
                        <img src="./facebook.jpg"></img>
                        <Card className="card-login bg-success">
                            <CardHeader>
                                <CardHeader>
                                    <h3 className="header text-center text-white">You will receive reset link</h3>
                                </CardHeader>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className="text-center">
                                    <FormGroup>
                                        <Input
                                            type="email"
                                            name="userEmail"
                                            value={userEmail}
                                            autoComplete="off"
                                            placeholder="Write your e-mail here"
                                            id="userEmail"
                                            onChange={event => onChangeHandler(event)}
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <div className="row  text-center">
                                    <div className="col-12 text-danger font-weight-bold mx-auto mb-2">{error}</div>
                                    <div className="col-6 mx-auto">
                                        <NavLink
                                            to="/sign-in"
                                            className="btn text-white btn-round mb-3 btn-block btn-primary font-weight-bold"
                                        >
                                            Back
                                        </NavLink>
                                    </div>
                                    <div className="col-6 mx-auto">
                                        <Button
                                            block
                                            className="btn-round mb-3 text-success"
                                            color="light"
                                            onClick={(event) => { resetPassword(event) }}
                                        >
                                            {!loading ? 'Reset Password' : <Spinner size="sm" color="primary" />}
                                        </Button>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <NotificationAlert ref={notifyRef} zIndex={9999} onClick={() => console.log("hey")} />
            </Container>
        </div>
    );
};
export default Reset;