import React, { Component, createContext } from "react";

export const ApiContext = createContext({ api: null });
class ApiProvider extends Component {
    state = {
        api: null,
        env: 'PROD'
    };

    componentDidMount = () => {
        if(this.state.env === 'DEV') {
            this.setState({
                api: 'https://api.qa.terrabot.club/v1'
            });
        } else if(this.state.env === 'PROD') {
            this.setState({
                api: 'https://api.terrabot.club'
            });
        } else {
            this.setState({
                api: 'https://api.qa.terrabot.club/v1'
            });
        }
    };

    render() {
        return (
            <ApiContext.Provider value={this.state.api}>
                {this.props.children}
            </ApiContext.Provider>
        );
    }
}

export default ApiProvider;