import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Input,
    Container,
    Col,
    Row,
    Spinner
} from "reactstrap";
import { auth, firestore, generateUserDocument } from '../../firebase'
import { UserContext } from '../../providers/UserProvider'

const SignUp = (props) => {
    useEffect(() => {
        let script3 = document.createElement("script");
        script3.src = "https://threejs.org/examples/js/libs/stats.min.js";
        script3.async = false;
        document.body.appendChild(script3);

        let script2 = document.createElement("script");
        script2.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
        script2.async = false;
        document.body.appendChild(script2);


        let script = document.createElement("script");
        script.src = "js/particles.js";
        script.async = false;
        document.body.appendChild(script);

        const params = new URLSearchParams(props.location.search);
        const ref = params.get('ref');
        console.log('ref', ref)
        if (ref)
            setRef(ref)

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
        }
    }, []);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
        }
    });

    const [userEmail, setEmail] = useState("");
    const [passwordFirst, setFirstPassword] = useState("");
    const [passwordSecond, setSecondPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [username, setUsername] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const [ref, setRef] = useState(null)

    const user = useContext(UserContext);
    console.log('User', user)

    const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
        setLoading(true)
        event.preventDefault();
        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password);
            await generateUserDocument(user, { displayName: '' });
            const botsRef = firestore.collection('users')
            let userObj = {
                language: "it",
                telegramTokens: "",
                theme: "tbtc",
                licenseAccepted: false,
                tags: ['safe', 'risky'],
            }
            if (ref)
                userObj.ref = ref
            const userDocumentCreated = await botsRef.doc(user.uid).set(userObj)
            setLoading(false)
            window.location.href = "/"
        }
        catch (error) {
            setLoading(false)
            console.log('Error', error)
            setError('Error signing up. Check your mail address!');
        }

        setEmail("");
        setFirstPassword("");
        setSecondPassword("");
        setDisplayName("");
        setUsername("");
    }

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        if (name === "userEmail") {
            setEmail(value);
        } else if (name === "userFirstPassword") {
            setFirstPassword(value);
        } else if (name === "userSecondPassword") {
            setSecondPassword(value);
        } else if (name === "displayName") {
            setDisplayName(value);
        } else if (name === "username") {
            setUsername(value)
        }
    };

    return (
        <div className="pt-2">
            <div id="particles-js"></div>
            <Container style={{ marginTop: '100px' }}>

                <Row>
                    <Col className="ml-auto mr-auto" lg="6" md="9" sm="10">
                        <img src="./facebook.jpg"></img>
                        <Card className="card-login bg-success">
                            <CardHeader>
                                <CardHeader>
                                    <h3 className="header text-center text-white">Register on TerraBot!</h3>
                                </CardHeader>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className="text-center">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="username"
                                            value={username}
                                            autoComplete="off"
                                            placeholder="Your username"
                                            id="username"
                                            onChange={event => onChangeHandler(event)}
                                        />
                                    </FormGroup>
                                </FormGroup>

                                <FormGroup className="text-center">
                                    <FormGroup>
                                        <Input
                                            type="email"
                                            name="userEmail"
                                            value={userEmail}
                                            autoComplete="off"
                                            placeholder="Write your e-mail here"
                                            id="userEmail"
                                            onChange={event => onChangeHandler(event)}
                                        />
                                    </FormGroup>
                                </FormGroup>

                                <FormGroup className="text-center">
                                    <FormGroup>
                                        <Input
                                            type="password"
                                            name="userFirstPassword"
                                            value={passwordFirst}
                                            autoComplete="off"
                                            placeholder="Write your password here"
                                            id="passwordFirst"
                                            onChange={event => onChangeHandler(event)}
                                        />
                                    </FormGroup>
                                </FormGroup>

                                <FormGroup className="text-center">
                                    <FormGroup check>
                                        <Input
                                            type="password"
                                            name="userSecondPassword"
                                            value={passwordSecond}
                                            autoComplete="off"
                                            placeholder="Repeat your password"
                                            id="passwordSecond"
                                            onChange={event => onChangeHandler(event)}
                                        />
                                    </FormGroup>
                                </FormGroup>


                            </CardBody>
                            <CardFooter>
                                <div className="row  text-center">
                                    <div className="col-12 text-danger font-weight-bold mx-auto mb-2">{error}</div>
                                    <div className="col-6 mx-auto">
                                        <Button
                                            block
                                            className="btn-round mb-3"
                                            color="light"
                                            onClick={(event) => { createUserWithEmailAndPasswordHandler(event, userEmail, passwordFirst) }}
                                        >
                                            {!loading ? 'Sign-up!' : <Spinner size="sm" color="primary" />}
                                        </Button>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default SignUp;