
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { auth, firestore } from "../firebase";
import SweetAlert from 'react-bootstrap-sweetalert';

import routes from "usersRoutes.js";

var ps;

class Artist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "white",
      activeColor: "primary",
      sidebarMini: true,
      tosContent: `
      <p><strong>Last update: 21 June, 2021</strong></p>

<p><a name="h.gjdgxs"></a> <strong>Please review the terms of service contained herein carefully. By clicking “I agree” or accessing and/or using any of the Services provided by TERRABOT, You have read, understood, and accepted all of the following Terms, as well as all of the terms which are incorporated herein by reference. </strong> </p>

<ol><li><p><strong>RIGHTS AND OBLIGATIONS</strong></p>

</li>
</ol><p>The following terms and conditions (the “<strong>Terms</strong>”) constitute a binding agreement between TerraBot FZO, a company registered under the laws of the United Arab Emirates (UAE), registered with registered address at the Dubai Silicon Oasis (“<strong>TERRABOT</strong>”), and the person, persons, or entity (“<strong>You</strong>” or “<strong>Your</strong>”) accessing and/or using the TerraBot trading algorithm through the website https://www.terrabot.club , the TerraBot telegram chat, the TerraBot youtube channel, and, in general, any other service provided by TerraBot in connection with algorithm trading system (the “<strong>Services</strong>”).</p>

<p>By accessing and/or using the Services, You have read, understood, and accepted all of the following Terms and agree to be legally bound by these Terms, in their most recent version; You further represent and warrant that: (i) You are of legal age, in Your jurisdiction, to enter into a binding agreement; and (ii) if You represent a corporation, governmental organization or other legal entity, You have the right, power and authority to enter into these Terms on behalf of such corporation, governmental organization or other legal entity and to legally bind it to these Terms.</p>

<p>Your access and/or use of the Services is contingent upon Your acceptance of these Terms.</p>

<p>TerraBot may amend these Terms at any time. Amendments will be effective fourteen (14) days after the modified terms and conditions have been posted on TERRABOT’s website, except for amendments that relate to new features or made for legal reasons, which will become effective immediately. If You do not agree to such modified terms, You should discontinue Your use of the Services.</p>

<ol start="2"><li><p><strong>SERVICES</strong></p>

</li>
</ol> <ol start="2"><ol><li><p><strong>IN GENERAL</strong></p>

</li>
</ol></ol><p>TERRABOT, through the Services, enables You to use an algorithm trading bot (the “<strong>Bot</strong>”). </p>

<p>To use the Bot You may register or create a User account, providing all required data or information in a complete and truthful manner. You may also use some Services without registering or creating a User account, however, this may cause limited availability of certain features or functions.</p>

<p>You are responsible for keeping their login credentials confidential and safe. For this reason, You are also required to choose passwords that meet the highest standards of strength. By registering, You agree to be fully responsible for all activities that occur under Your username and password.</p>

<p>You are required to immediately and unambiguously inform TERRABOT via the contact details indicated in this document, if You think Your personal information, including but not limited to user accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>

<p>Users can terminate their account and stop using the Service at any time. TERRABOT reserves the right, at its sole discretion, to suspend or delete at any time and without notice, Your accounts which it deems inappropriate, offensive or in violation of these Terms.</p>

<p>The suspension or deletion of Your account(s) shall not entitle You to any claims for compensation, damages or reimbursement.</p>

<p>The suspension or deletion of accounts due to causes attributable to You does not exempt You from paying any applicable fees or prices.</p>

<ol start="2"><ol start="2"><li><p><strong>SERVICES NOT OFFERED BY TERRABOT</strong></p>

</li>
</ol></ol><p>By accessing and/or using the Services, You acknowledge and agree that TERRABOT is not offering any financial service and shall not receive any deposits from You. Therefore, TERRABOT is not executing any suitability or awareness verification, such verification being required by the broker You will use together with the Services offered by TERRABOT. TERRABOT is a mere service provider and is not subject to any supervision. </p>

<p>You acknowledge and agree that TERRABOT cannot guarantee the operation of the broker used for the execution of the Bot. The decision about the broker to be used in relation with the Bot is Yours and You have to support the risks related to this decision. TERRABOT has no control over any external broker and cannot be liable for any activity provided or not provided by such broker. In particular TERRABOT cannot be deemed responsible in case the broker running the Bot did not place, modify, cancel or execute a trade. </p>

<p>You further acknowledge and agree that TERRABOT does not acts or serves as Your broker, intermediary, agent, or advisor with respect to any action (including refraining from any action) You make or intend to make using the Services, but only as a custodian.</p>

<p>In no event will TERRABOT be liable for any loss or damages arising from or related to the storage of Your Tokens.</p>

<ol start="2"><ol start="3"><li><p><strong>SERVICE LIMITATIONS AND MODIFICATIONS</strong></p>

</li>
</ol></ol><p>TERRABOT will make reasonable efforts to keep the Services operational. However, certain technical difficulties or maintenance may, from time to time, result in temporary interruptions. To the extent permissible under applicable law, TERRABOT reserves the right, periodically and at any time, to modify or discontinue, temporarily or permanently, functions and features of the Services, with or without notice, without liability to You, for any interruption, modification, or discontinuation of the Services or any function or feature thereof.</p>

<ol start="3"><li><p><strong>KNOWLEDGE REQUIRED</strong></p>

</li>
</ol><p>By accessing and/or using the Services, You represent and warrant that (i) You fully understand and have significant experience of tokens, cryptocurrencies and digital assets, as well as of blockchain-based infrastructures and services, and (ii) You fully understand the risks associated with the trading of tokens, cryptocurrencies and digital assets as well as the mechanics related to the use of such tokens, cryptocurrencies and digital assets (including with respect to their storage and exchange).</p>

<p>By accessing and/or using the Services, You acknowledge that, tokens, cryptocurrencies and digital assets, including the Tokens, trading markets are extremely volatile and shift quickly in terms of, among others, liquidity, market depth and other characteristics. There is no guarantee against losses for accessing and/or using the Services. <strong>By accessing and/or using the Services, You represent and warrant that You are not entering into transactions that are above Your financial abilities.</strong> Also, tokens, cryptocurrencies and digital assets, including the Tokens, are not suitable for people without the relevant knowledge and/or experience. By accessing and/or using the Services, You further represent and guarantee that You are aware of the risks related to tokens, cryptocurrencies and digital assets, for which You are solely responsible and liable.</p>

<p>You further acknowledge and agree that TERRABOT does not provide any advice, guidance or recommendations on the opportunity to invest into, disinvest from, or remain invested in the Tokens. If You are considering purchasing Tokens as an investment, You should first contact your financial advisor for any such advice, guidance or recommendation.</p>

<ol start="4"><li><p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>

</li>
</ol><p>You acknowledge and agree that the Services are the property of TERRABOT or its licensors. Subject to Your compliance with these Terms, TERRABOT grants You a limited right to access and/or use the Services. The right to access and/or use the Services is a non-exclusive, non-transferable, revocable, limited licence, and it is subject to the limitations and obligations contained herein. Nothing in these Terms gives You any licence (other than as set out in this section), right, title, or ownership of, in, or to any of the Services.</p>

<p>You acknowledge and agree that TERRABOT retains all rights, title, and interest in and to all copyrights, trademarks, trade secrets, patents, and any other proprietary rights in the Services, the software and application programming interfaces (APIs) comprising the Services, and all content therein. You acknowledge and agree that “TERRABOT”, their trademarks, service marks, logos and graphics are the registered trademarks or trademarks of TERRABOT.</p>

<ol start="5"><li><p><strong>DATA PROTECTION</strong></p>

</li>
</ol><p>In order to provide You with the Services, You acknowledge and agree that TERRABOT may collect, store and process Your personal data and/or information. By accessing and/or using the Services, You acknowledge to have read, understood, and accepted the terms of TERRABOT’s privacy policy, and You further acknowledge and agree that TERRABOT may use such data and/or information in accordance with the terms of its privacy policy.</p>

<ol start="6"><li><p><strong>TERMINATION</strong></p>

</li>
</ol><p>TERRABOT may terminate these Terms or suspend Your access to the Services at any time, including, without limitation, in the event of Your alleged or actual misuse of the Services or breach of these Terms.</p>

<ol start="7"><li><p><strong>LIMITATION OF LIABILITY</strong></p>

</li>
</ol><p>In no event will TERRABOT, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors be liable for any loss or damages, including without limitation, direct, indirect, special, consequential, or punitive loss or damages, arising from or related to Your use of the Services, whether in an action of contract, tort or otherwise, and regardless of whether such damages were foreseeable and whether or not TERRABOT was advised of the possibility of such damages.</p>

<p><strong>Without limiting the generality of the foregoing, TERRABOT takes no responsibility for and will not be liable for any financial or other loss or damages arising from or related to the use of the Services, including, without limitation, to any of the following: (i) delays, interruption or loss of services; (ii) technical failure, malfunction or shutdown; (iii) server failure, hacks or unavailability; (iv) data loss; (v) corrupted data on TERRABOT’s servers; (vi) failure to update or provide correct information; (vii) “phishing” or other websites masquerading as TERRABOT; (viii) stolen, lost, or unauthorized use of Your means of authorization; (ix) loss of business or goodwill.</strong></p>

<p>By running the Bot, You acknowledges and accepts, personally and towards TERRABOT, the existence of the operational risks, technical risks and that no liability whatsoever can be ascribed to TERRABOT for the above or any other kind of risk related to the use of the System. </p>

<p><strong>In no event shall TERRABOT or its agents or representatives be liable directly or indirectly to You or any third party for any loss of revenue, loss of profits, loss of use, opportunity cost or other savings, or damage suffered or costs and expenses incurred by anyone, or from any cause whatsoever, whether direct, indirect, special, incidental, consequential, or otherwise arising out of the furnishing, or performance of the Bot, including any trading loss or of any claim, suit or allegation that any Bot message or transmission did not result in a placement, modification or cancellation of any order.</strong></p>

<p>The limitation of liability set out above shall not be applicable in case of loss or damages caused by TERRABOT or any of its employees by intentional misconduct or gross negligence.</p>

<p><strong>The Services support or are integrated with third party services. TERRABOT takes no responsibility for any third party services and will not be liable for any loss or damages caused by such third party services. In particular no liability is foreseen in case a damage suffered because the broker used for running the Bot has not placed, modified or cancelled one or more orders.</strong></p>

<p>The Services are provided on an “as is” and “as available” basis without any representation or warranty of any kind, whether express or implied, to the maximum extent permitted by applicable laws. TERRABOT disclaims any implied warranties of title, merchantability, fitness for a particular purpose and/or non-infringement.</p>

<p>In no event will the aggregate liability of TERRABOT, arising out of or related to these Terms and/or the Services, exceed the amount of USD 100 (one hundred).</p>

<ol start="8"><li><p><strong>RESTRICTIONS</strong></p>

</li>
</ol><p>By accessing and/or using the Services, You acknowledge and agree that You shall not use the Services if applicable laws, based on Your country of location, residency and/or citizenship, prohibit You from doing so in accordance with these Terms. You further acknowledge and agree that TERRABOT reserves the right to refuse access to and/or use of the Services, at its exclusive discretion, if the laws and regulations of Your country of location, residency and/or citizenship are not compliant with TERRABOT’S internal policies.</p>

<p>By accessing and/or using the Services, You acknowledge and agree that You shall not use, or assist third parties to use, the Services in any way which may constitute a contravention of applicable laws or which may contradict the purposes or hinder the operations of the Services or hinder the operations of other users of the Services.</p>

<p>Without limitation to the foregoing, You acknowledge and agree that You shall not use, or assist third parties to use the Services to, without limitation, avoid taxes, engage into activities that may amount to market abuse (including, without limitation, front-running, wash trades, etc.), engage into illegal gambling, frauds, money laundering or terrorist activities.</p>

<ol start="9"><li><p><strong>INDEMNIFICATION</strong></p>

</li>
</ol><p>To the fullest extent permitted under applicable laws, You agree to hold harmless and indemnify TERRABOT, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors from and against all third party claims and all liabilities, damages, assessments, losses, costs, or expenses (including reasonable attorney fees) resulting from or arising out of (i) Your alleged or actual breach of these Terms, including, without limitation, Your express representations and warranties; (ii) Your alleged or actual use or misuse of the Services; and (ii) Your alleged or actual infringement or violation of any laws or of the rights of a third party.</p>

<ol start="10"><li><p><strong>TAXES</strong></p>

</li>
</ol><p>You shall be solely responsible for any taxes applying to the payments You make or receive through the Services, and to collect, report, and remit such taxes to the appropriate tax authorities. You acknowledge and agree that, at the moment, TERRABOT is not able to draft a taxation report concerning the operation executed and that it is Your duty to collect all the information required by the competent tax authorities in order to comply with Your tax obligations.</p>

<ol start="11"><li><p><strong>MISCELLANEOUS</strong></p>

</li>
</ol> <ol start="13"><ol><li><p><strong>ENTIRE AGREEMENT</strong></p>

</li>
</ol></ol><p><a name="h.30j0zll"></a> These Terms constitute all the terms and conditions agreed upon between You and TERRABOT and supersede any prior agreements in relation to the subject matter of these Terms, whether written or oral.</p>

<ol start="13"><ol start="2"><li><p><strong>SEVERABILITY AND WAIVER</strong></p>

</li>
</ol></ol><p>Unless as otherwise stated in these Terms, should any provision of these Terms be held totally or partially invalid or unenforceable, such invalidity or unenforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of these Terms, and the application of that provision shall be enforced to the extent permitted by applicable laws.</p>

<p>The failure of TERRABOT to exercise or enforce any of the rights or provisions of these Terms shall not considered as a waiver of TERRABOT’s rights to do so.</p>

<ol start="13"><ol start="3"><li><p><strong>ASSIGNMENT</strong></p>

</li>
</ol></ol><p>TERRABOT may assign these Terms and/or delegate any of its obligations hereunder, in whole or in part. You may not assign these Terms or any part of them, nor transfer or sub-license Your rights under these Terms to any third party, unless in connection with the transfer of the Tokens.</p>

<ol start="13"><ol start="4"><li><p><strong>NO PARTNERSHIP</strong></p>

</li>
</ol></ol><p>Nothing contained in this Agreement shall be deemed or construed to create a principal and agent, partnership or joint venture relationship between You and TERRABOT.</p>

<ol start="13"><ol start="5"><li><p><strong>FORCE MAJEURE</strong></p>

</li>
</ol></ol><p>TERRABOT will not be deemed in default of these Terms to the extent that performance of its obligations is delayed or prevented by reason of any external force including, without limitation, war, insurrections, bank failures, strikes, fires, floods, earthquakes, labour disputes, epidemics, governmental regulations, freight embargoes, natural disaster, act of government or any other cause beyond TERRABOT’s reasonable control.</p>

<ol start="13"><ol start="6"><li><p><strong>GOVERNING LAW AND JURISDICTION</strong></p>

</li>
</ol></ol><p>These Terms are subject to and governed by <strong>UEA law</strong> to the exclusion of international private law and any international treaties. All disputes arising from or under these Terms shall be subject to the <strong>exclusive jurisdiction of the UEA’s competent courts</strong>.</p>

<ol start="13"><ol start="7"><li><p><strong>CONTACTS</strong></p>

</li>
</ol></ol><p>If You have any questions regarding these Terms, please contact us at info@terrabot.club.</p>

<style>p { font-size: 60%; margin-bottom: 0.1in; direction: ltr; color: #000000; line-height: 115%; text-align: left; orphans: 2; widows: 2; background: transparent; text-decoration: none }p.western { font-family: "Calibri", serif; font-size: 11pt; so-language: en-US; font-style: normal; font-weight: normal }p.cjk { font-family: "Calibri"; font-size: 11pt; so-language: zh-CN; font-style: normal; font-weight: normal }p.ctl { font-family: "Calibri"; font-size: 11pt; so-language: hi-IN; font-style: normal; font-weight: normal }</style>

`,
      tosVisible: false
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged(userAuth => {
      // console.log('user has changed from layout', userAuth)
      this.setState({ user: userAuth });

      firestore.collection("users").doc(userAuth.uid)
        .onSnapshot((doc) => {
          this.setState({
            tosVisible: !doc.data().licenseAccepted
          })
        });
    });

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    this.handleActiveClick('success')
    this.handleMiniClick()
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    let tosVisible = this.state.tosVisible;
    return (

      <div className="wrapper">
        {this.state.tosVisible === false ?
          <div>
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>{this.getRoutes(routes)}</Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
              -1 ? null : (
              <Footer fluid />
            )
          }

        </div>
        </div> : <div>
            <SweetAlert
              style={{ width: '90%', textAlign: 'left' }}
              onConfirm={() => {

                firestore.collection("users").doc(this.state.user.uid).set({
                  licenseAccepted: true
                }, { merge: true })
                  .then(() => {
                    console.log("Document successfully written!");
                    this.setState({
                      tosVisible: false
                    })
                    window.location.href = '/user/home'
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error);
                  });
              }}
              show={tosVisible}
              confirmBtnText="Accept"
              title="Terms of Service"
            >
              <div>
                <h4>
                  <div
                    style={{ textAlign: 'left', paddingLeft: '20px' }}
                    dangerouslySetInnerHTML={{ __html: this.state.tosContent }}
                  />
                </h4>
              </div>
            </SweetAlert>
            </div>
        }
      </div>
    );
  }
}

export default Artist;
