/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import classnames from "classnames"
import {
  Navbar,
  Container,
  NavItem,
  Nav,
  NavLink,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  Collapse,
} from "reactstrap"
import { auth, firestore } from "../../firebase"
import { UserContext } from '../../providers/UserProvider'

class AdminNavbar extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      loading: false,
      active: null
    };
  }

  async componentDidMount() {
    const user = this.context

    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        // get user general info
        const userRef = firestore.collection('users').doc(userAuth.uid);
        const userDetail = await userRef.get()
        // set state
        this.setState({
          user: userAuth,
          userBalance: userDetail.data().balance
        });
        // update balance
        this.updateBots(userAuth)

        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
        storage.tags = userDetail.data().tags
        localStorage.setItem('preferences', JSON.stringify(storage));
      }
    });
    window.addEventListener("resize", this.updateColor);
  }

  updateBots = async (user) => {
    firestore.collection('bots')
      .where('userId', '==', user.uid)
      .onSnapshot((snapshot) => {
        const accounts = []
        const accountsWithoutKeys = []
        snapshot.docs.forEach(account => {
          let accountObj = { id: account.id, ...account.data() }
          accounts.push(accountObj)
          accountsWithoutKeys.push({
            id: account.id,
            exchangeType: accountObj.exchangeType,
            alias: accountObj.alias
          })
        })
        this.setState({
          bots: accounts
        })
        localStorage.setItem('fullBots', JSON.stringify(accountsWithoutKeys))
        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
        if (storage.account) {
          const currentBotId = Buffer.from(storage.account, 'base64').toString('')
          const currentBotDetails = accounts.filter(b => b.id === currentBotId)[0]
          this.setState({
            active: {
              alias: currentBotDetails.alias,
              exchangeType: currentBotDetails.exchangeType
            }
          })
        } else {
          if (accounts.length > 0)
            // accounts but not loaded, switch  
            this.setState({
              active: 0
            })
          else if (accounts.length === 0)
            // no accounts yet, firs time user -> create  
            this.setState({
              active: -1
            })
        }


      })
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  renderSwitcherComp = () => {
    if (this.state?.active !== null) {
      switch (this.state.active) {
        case 0:
          return <span>Switch account</span>
        case -1:
          return <span>Create account</span>
        default:
          switch (this.state.active.exchangeType) {
            case 'binancef':
              return <span>{this.state.active.alias} <img src="https://upload.wikimedia.org/wikipedia/commons/5/57/Binance_Logo.png" width="15px" /></span>
            case 'okexm':
              return <span>{this.state.active.alias} <img src="https://www.bestcoinlist.com/img/exchange-logo/okex1.png" width="15px" /></span>
          }
      }
    } else {
      return <span>Loading..</span>
    }
  }

  render() {
    return (
      <>
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            {/*<Button
              className="btn-icon btn-round mr-4"
              color="default"
              id="minimizeSidebar"
              onClick={this.props.handleMiniClick}
            >
              <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
              <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
            </Button>*/}
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <UncontrolledDropdown>
                  <NavLink>
                    {this.state.userBalance ? `Account balance ${this.state.userBalance?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} USD` : ''} &nbsp;
                  </NavLink>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    {this.renderSwitcherComp()}
                  </DropdownToggle>
                  {this.state.bots ?
                    <DropdownMenu
                      persist
                      aria-labelledby="navbarDropdownMenuLink"
                      right
                    >
                      {
                        this.state.bots?.map((bot, key) => <DropdownItem
                          key={key}
                          onClick={(e) => {
                            e.preventDefault()
                            let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
                            storage.account = Buffer.from(bot.id).toString('base64')
                            console.log('switched to', storage.account)
                            localStorage.setItem('preferences', JSON.stringify(storage));
                            window.location.reload();
                          }}
                        >
                          {
                            bot.exchangeType === 'okexm' && <img src="https://www.bestcoinlist.com/img/exchange-logo/okex1.png" width="15px" />
                          }
                          {
                            bot.exchangeType === 'binancef' && <img src="https://upload.wikimedia.org/wikipedia/commons/5/57/Binance_Logo.png" width="15px" />
                          }
                          &nbsp;{bot.alias}
                        </DropdownItem>
                        )

                      }
                    </DropdownMenu>

                    : ''
                  }
                </UncontrolledDropdown>
                <NavItem style={{ cursor: 'pointer' }}>
                  <NavLink
                    className="btn-magnify"
                    onClick={(e) => {
                      auth.signOut();
                      localStorage.removeItem('preferences')
                      window.location.href = "/sign-in"
                    }}
                  >
                    Logout <i className="nc-icon nc-button-power" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
