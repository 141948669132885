import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Table,
    FormGroup,
    Label,
    Form,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
    Spinner
} from 'reactstrap';
import { UserContext } from "../../../providers/UserProvider";
import './analytics.css'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import ApiService from 'components/ApiService';

const Analytics = () => {
    console.log('init component')
    const today = moment();
    let apiService = new ApiService()
    const [loading, setLoading] = useState(false)

    const [from, setFrom] = useState(today)
    const [to, setTo] = useState(today)
    const [profits, setProfits] = useState([])

    const [walletInfo, setWalletInfo] = useState({})

    const [sectionVisible, setSection] = useState('')

    const [columnsOrders, setColumnOrders] = useState({})

    const user = useContext(UserContext);

    const changedTypeOfReport = async (value) => {
        setLoading(true)
        setSection('')
        if (user) {
            const headers = { headers: { 'authorization': `Bearer ${user.Aa}` } }
            let records = null
            let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
            if (value === 'tp') {
                records = await apiService.takeProfits(
                    Buffer.from(storage.account, 'base64'),
                    headers,
                    {
                        from: from.format('YYYY-MM-DD'),
                        to: to.format('YYYY-MM-DD')
                    }
                )
                setProfits(records.records)
                setSection('tp')
            } else if (value === 'p&l') {
                records = await apiService.profitsAndLosses(
                    Buffer.from(storage.account, 'base64'),
                    headers,
                    {
                        from: from.format('YYYY-MM-DD'),
                        to: to.format('YYYY-MM-DD')
                    }
                )
                const positionsAsArray = Object.keys(records.positions).map((key, id) => {
                    return records.positions[key]
                })
                records.positions = positionsAsArray
                setWalletInfo(records)
                setSection('p&l')
            } else if (value === 'charts') {
                setSection('charts')
            }
        }
        setLoading(false)
    }

    const refreshReport = async () => {
        setLoading(true)
        const headers = { headers: { 'authorization': `Bearer ${user.Aa}` } }
        let records = null
        let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
        const value = sectionVisible
        if (value === 'tp') {
            records = await apiService.takeProfits(
                Buffer.from(storage.account, 'base64'),
                headers,
                {
                    from: from.format('YYYY-MM-DD'),
                    to: to.format('YYYY-MM-DD')
                }
            )
            setProfits(records.records)
        } else if (value === 'p&l') {
            records = await apiService.profitsAndLosses(
                Buffer.from(storage.account, 'base64'),
                headers,
                {
                    from: from.format('YYYY-MM-DD'),
                    to: to.format('YYYY-MM-DD')
                }
            )
            const positionsAsArray = Object.keys(records.positions).map((key, id) => {
                return records.positions[key]
            })
            records.positions = positionsAsArray
            setWalletInfo(records)
        }
        setLoading(false)
    }

    useEffect(() => {
        console.log('effect from', from)
        changedTypeOfReport(sectionVisible)
    }, [from])

    useEffect(() => {
        console.log('effect to', to)
        changedTypeOfReport(sectionVisible)
    }, [to])

    useEffect(() => {
        console.log('reloaded')
        if (user) {
            changedTypeOfReport('tp')
        }
    }, [user]);

    const sortStrategies = (param) => {
        const positionsAsArray = Object.keys(walletInfo.positions).map((key, id) => {
            return walletInfo.positions[key]
        })
        let orderedStrategies = []
        console.log(positionsAsArray)
        if (columnsOrders[param] && columnsOrders[param] === 1) {
            orderedStrategies = positionsAsArray.sort((a, b) => {
                if (a[param] < b[param]) return 1;
                if (a[param] > b[param]) return -1;
                return 0;
            });
        } else {
            orderedStrategies = positionsAsArray.sort((a, b) => {
                if (a[param] > b[param]) return 1;
                if (a[param] < b[param]) return -1;
                return 0;
            });
        }

        walletInfo.positions = positionsAsArray

        setColumnOrders({
            ...columnsOrders,
            [param]: columnsOrders[param] === 1 ? 0 : 1
        })
        setWalletInfo(walletInfo)
    }

    /**
     * asset
     * @param {string} quote 
     */
    const getFixedPositions = (quote) => {
        switch (quote) {
            case 'USDT':
                return 4;
            case 'BUSD':
                return 2;
            case 'BNB':
                return 8;
            default:
                return 8;
        }
    }

    const valid = function (current) {
        const oneWeekAgo = moment().subtract(7, 'day')
        return current.isAfter(oneWeekAgo) && current.isBefore(today);
    };

    return <div className="content">
        {
            user ?
                <div>
                    <div className="row">
                        <div className="col-12 col-md-2">
                            <Form>
                                <b>From</b>&nbsp;
                                <Datetime
                                    className="mx-auto"
                                    isValidDate={valid}
                                    onChange={(e) => {
                                        setFrom(e)
                                    }}
                                    initialValue={from}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                />
                            </Form>
                        </div>
                        <div className="col-12 col-md-2">
                            <Form>
                                &nbsp;<b>to</b>&nbsp;
                                <Datetime
                                    className="mx-auto"
                                    isValidDate={valid}
                                    onChange={(e) => {
                                        setTo(e)
                                    }}
                                    initialValue={today}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                />
                            </Form>
                        </div>
                        <div className="col-12 col-md-5 mx-auto">
                            <Form inline className="col-12 pt-3">
                                <b>Choose:</b>
                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input type="radio" defaultChecked name="radio1" value="tp" onChange={(e) => changedTypeOfReport(e.target.value)} />{' '}
                                        Take Profits
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input type="radio" name="radio1" value="p&l" onChange={(e) => changedTypeOfReport(e.target.value)} />{' '}
                                        P&L
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input type="radio" name="radio1" value="charts" onChange={(e) => changedTypeOfReport(e.target.value)} />{' '}
                                        TP distribution
                                    </Label>
                                </FormGroup>
                            </Form>
                        </div>
                        <div className="col-12 col-md-2">
                            <button
                                className="btn btn-block btn-primary mt-3"
                                onClick={() => refreshReport()}
                            >
                                {!loading ? <span><i className="fa fa-refresh" aria-hidden="true"></i> Update</span> : <Spinner size="sm" color="light" />}
                            </button>
                        </div>
                    </div>
                    {
                        sectionVisible === 'tp'
                            ?
                            <div>
                                <Table responsive bordered striped className="risk-calculator mb-4">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Symbol</th>
                                            <th className="text-center">Side</th>
                                            <th className="text-center">filled qty</th>
                                            <th className="text-center">AVG price</th>
                                            <th className="text-center">Grid step</th>
                                            <th className="text-center">Realized profit</th>
                                            <th className="text-center">Take Profit Step</th>
                                            <th className="text-center">Executed at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            profits?.map((profit, key) => {
                                                return <tr key={key}>
                                                    <td className="text-center">{profit.symbol}</td>
                                                    <td className="text-center">{profit.side}</td>
                                                    <td className="text-center">{profit.filledQuantity.toFixed(4)}</td>
                                                    <td className="text-center">{profit.averagePrice.toFixed(4)}</td>
                                                    <td className="text-center">{profit.currentGridStep.toFixed(0)}</td>
                                                    <td className="text-center">{profit.realizedProfit.toFixed(4)}</td>
                                                    <td className="text-center">{profit.totalGridSteps.toFixed(0)}</td>
                                                    <td className="text-center">{new moment(profit.executedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                {/*<div className="row text-center mx-auto">
                                    <Pagination className="row text-center mx-auto">
                                        <PaginationItem>
                                            <PaginationLink first href="#" />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink previous href="#" />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                2
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>*/}
                            </div>
                            :
                            <div>
                            </div>
                    }
                    {
                        sectionVisible === 'p&l' && walletInfo ?
                            <div className="row">
                                <div className="col-xl-4 col-md-4 col-lg-4 col-6 mx-auto">
                                    <Table bordered striped className="mb-4 profits">
                                        <thead>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className="text-center" colSpan="2">PROFITS</th>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Unrealized Profit</td>
                                                <td className="text-center">{walletInfo.totUnrealizedProfit.toFixed(2)} USDT</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Realized Profit</td>
                                                <td className="text-center">{walletInfo.totRealizedProfit.toFixed(2)} USDT</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Realized Profit %</td>
                                                <td className="text-center">{(walletInfo.totRealizedProfitPerc * 100).toFixed(2)}%</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-xl-4 col-md-4 col-lg-4 col-6 mx-auto">
                                    <Table bordered striped className="mb-4 profits">
                                        <tbody>
                                            <tr>
                                                <th className="text-center" colSpan="2">LAST GRID RISK	</th>
                                            </tr>

                                            <tr>
                                                <td className="text-center col-6">Exposure</td>
                                                <td className="text-center">{walletInfo.lastGridExposure.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Loss</td>
                                                <td className="text-center">{walletInfo.lastGridLoss.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Available balance</td>
                                                <td className="text-center">{walletInfo.lastGridAvailableBalance.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Exposure %</td>
                                                <td className="text-center">{(walletInfo.lastGridExposurePerc * 100).toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Max loss to liquidation %</td>
                                                <td className="text-center">{(walletInfo.lastGridLossToLiquidation * 100).toFixed(2)}%</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-xl-4 col-md-4 col-lg-4 col-6 mx-auto">
                                    <Table bordered striped className="mb-4 profits">
                                        <thead>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className="text-center" colSpan="2">WALLET</th>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Balance</td>
                                                <td className="text-center">{walletInfo.balance.toFixed(4)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Position Initial Margin</td>
                                                <td className="text-center">{walletInfo.totInitialMargin.toFixed(4)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Position Maintenance Margin</td>
                                                <td className="text-center">{walletInfo.totalMaintMargin.toFixed(4)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Open Order Initial Margin</td>
                                                <td className="text-center">{walletInfo.totOpenOrderInitialMargin.toFixed(4)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center col-6">Available Balance</td>
                                                <td className="text-center">{walletInfo.availableBalance.toFixed(4)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                                <Table bordered responsive striped className="mt-4" id="pl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Symbol</th>
                                            <th style={{ textOrientation: 'upright', writingMode: 'vertical-rl' }}>LEV</th>
                                            <th style={{ lineBreak: 'anywhere' }}>SIDE</th>
                                            <th
                                                className="text-center"
                                                onClick={() => sortStrategies('unrealizedProfit')}
                                            >Unrealized Profit&nbsp;
                                                {
                                                    columnsOrders['unrealizedProfit'] === 1 ?
                                                        <i className="fa fa-arrow-down text-danger" aria-hidden="true"></i>
                                                        :
                                                        <i className="fa fa-arrow-up text-success" aria-hidden="true"></i>

                                                }
                                            </th>
                                            <th
                                                colSpan={2}
                                                className="text-center"
                                                onClick={() => sortStrategies('realizedPnl')}
                                            >Net Realized Profit&nbsp;
                                                {
                                                    columnsOrders['realizedPnl'] === 1 ?
                                                        <i className="fa fa-arrow-down text-danger" aria-hidden="true"></i>
                                                        :
                                                        <i className="fa fa-arrow-up text-success" aria-hidden="true"></i>

                                                }</th>
                                            <th className="text-center">Gross Realized Profit</th>
                                            <th className="text-center">Commission Fees</th>
                                            <th className="text-center">Funding Fees</th>
                                            <th colSpan={2} className="text-center">Exposure</th>
                                            <th
                                                colSpan={2}
                                                className="text-center"
                                                onClick={() => sortStrategies('tradeCount')}
                                            >Number of take profits&nbsp;
                                                {
                                                    columnsOrders['takeProfits'] === 1 ?
                                                        <i className="fa fa-arrow-down text-danger" aria-hidden="true"></i>
                                                        :
                                                        <i className="fa fa-arrow-up text-success" aria-hidden="true"></i>

                                                }
                                            </th>
                                            <th colSpan={4} className="text-center">Open Grid Orders</th>
                                            <th colSpan={3} className="text-center">Open TP Orders</th>
                                            <th className="text-center">Position Amount</th>
                                            <th className="text-center">Position entry price</th>
                                            <th className="text-center">Market price</th>
                                            <th className="text-center">Market price to TP %</th>
                                            <th className="text-center">Last grid cost</th>
                                            <th className="text-center">Last grid entry price</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th className="text-center">value</th>
                                            <th className="text-center">% of wallet</th>
                                            <th className="text-center"></th>
                                            <th className="text-center"></th>
                                            <th className="text-center">n°</th>
                                            <th className="text-center">amount</th>
                                            <th className="text-center">value</th>
                                            <th className="text-center">% of wallet</th>
                                            <th className="text-center">n°</th>
                                            <th className="text-center">value</th>
                                            <th className="text-center">price</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            walletInfo?.positions ?
                                                Object.keys(walletInfo.positions).map((key, id) => {
                                                    return <tr key={id}>
                                                        <td>{walletInfo.positions[key].symbol
                                                            .replace('_LONG', '')
                                                            .replace('_SHORT', '')
                                                        }</td>
                                                        <td>{walletInfo.positions[key].leverage}x</td>
                                                        <td>{
                                                            walletInfo.positions[key].positionSide
                                                                === 'LONG' ?
                                                                < span className="fa fa-arrow-up" style={{ color: 'green' }} />
                                                                :
                                                                <span className="fa fa-arrow-down" style={{ color: 'red' }} />
                                                        }</td>
                                                        <td>{walletInfo.positions[key].unrealizedProfit.toFixed(2)}</td>
                                                        <td>{walletInfo.positions[key].realizedPnl.toFixed(2)}</td>
                                                        <td>{(walletInfo.positions[key].realizedPnlPerc * 100).toFixed(2)}%</td>
                                                        <td>{walletInfo.positions[key].grossRealizedPnl.toFixed(2)}</td>
                                                        <td>{walletInfo.positions[key].commission.toFixed(2)}</td>
                                                        <td>{walletInfo.positions[key].fundingFee.toFixed(2)}</td>

                                                        <td>{walletInfo.positions[key].exposure.toFixed(2)}</td>
                                                        <td>{(walletInfo.positions[key].exposurePerc * 100).toFixed(2)}%</td>
                                                        <td>{walletInfo.positions[key].tradeCount}</td>
                                                        <td>{(walletInfo.positions[key].tradeCountPerc * 100).toFixed(2)}%</td>
                                                        <td>{walletInfo.positions[key].openingOrderCount}</td>
                                                        <td>{walletInfo.positions[key].openingOrderQty.toFixed(2)}</td>

                                                        <td>{walletInfo.positions[key].openingOrderValue.toFixed(2)}</td>
                                                        <td>{(walletInfo.positions[key].openingOrderValuePerc * 100).toFixed(2)}%</td>
                                                        <td>{walletInfo.positions[key].closingOrderCount}</td>
                                                        <td>{walletInfo.positions[key].closingOrderValue.toFixed(4)}</td>
                                                        <td>{walletInfo.positions[key].tpPrice.toFixed(4)}</td>

                                                        <td>{walletInfo.positions[key].positionAmt.toFixed(4)}</td>
                                                        <td>{walletInfo.positions[key].entryPrice.toFixed(4)}</td>
                                                        <td>{walletInfo.positions[key].currentPrice.toFixed(4)}</td>
                                                        <td>{(walletInfo.positions[key].diffCurPriceFromTpPerc * 100).toFixed(2)}%</td>

                                                        <td>{walletInfo.positions[key].lastGridCost.toFixed(4)}</td>
                                                        <td>{walletInfo.positions[key].lastGridEntryPrice.toFixed(4)}</td>

                                                    </tr>
                                                }) : ''
                                        }
                                        {
                                            walletInfo?.positionsTot ? <tr className="font-weight-bold">
                                                <td>{walletInfo.positionsTot.symbol
                                                    .replace('_LONG', '')
                                                    .replace('_SHORT', '')
                                                }</td>
                                                <td></td>
                                                <td>{
                                                    walletInfo.positionsTot.symbol
                                                        .indexOf('_LONG') > -1 ?
                                                        < span className="fa fa-arrow-up" style={{ color: 'green' }} />
                                                        :
                                                        <span className="fa fa-arrow-down" style={{ color: 'red' }} />
                                                }</td>
                                                <td>{walletInfo.positionsTot.unrealizedProfit.toFixed(2)}</td>
                                                <td>{walletInfo.positionsTot.realizedPnl.toFixed(2)}</td>
                                                <td>{(walletInfo.positionsTot.realizedPnlPerc * 100).toFixed(2)}%</td>
                                                <td>{walletInfo.positionsTot.grossRealizedPnl.toFixed(2)}</td>
                                                <td>{walletInfo.positionsTot.commission.toFixed(2)}</td>
                                                <td>{walletInfo.positionsTot.fundingFee.toFixed(2)}</td>

                                                <td>{walletInfo.positionsTot.exposure.toFixed(2)}</td>
                                                <td>{(walletInfo.positionsTot.exposurePerc * 100).toFixed(2)}%</td>
                                                <td>{walletInfo.positionsTot.tradeCount}</td>
                                                <td>{(walletInfo.positionsTot.tradeCountPerc * 100).toFixed(2)}%</td>
                                                <td>{walletInfo.positionsTot.openingOrderCount}</td>
                                                <td>{walletInfo.positionsTot.openingOrderQty.toFixed(2)}</td>

                                                <td>{walletInfo.positionsTot.openingOrderValue.toFixed(2)}</td>
                                                <td>{(walletInfo.positionsTot.openingOrderValuePerc * 100).toFixed(2)}%</td>
                                                <td>{walletInfo.positionsTot.closingOrderCount}</td>
                                                <td>{walletInfo.positionsTot.closingOrderValue?.toFixed(2)}</td>
                                                <td>{walletInfo.positionsTot.tpPrice.toFixed(2)}</td>

                                                <td>{walletInfo.positionsTot.positionAmt}</td>
                                                <td>{walletInfo.positionsTot.entryPrice.toFixed(2)}</td>
                                                <td>{walletInfo.positionsTot.currentPrice.toFixed(2)}</td>
                                                <td>{(walletInfo.positionsTot.diffCurPriceFromTpPerc * 100).toFixed(2)}%</td>

                                                <td>{walletInfo.positionsTot.lastGridCost.toFixed(2)}</td>
                                                <td>{walletInfo.positionsTot.lastGridEntryPrice.toFixed(2)}</td>

                                            </tr>
                                                :
                                                <tr></tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            :
                            <div>
                            </div>
                    }
                    {
                        sectionVisible === 'charts' ?
                            <div className="row text-center">
                                <div className="col-12 col-md-8 mx-auto">
                                    <iframe style={{
                                        background: '#FFFFFF',
                                        border: 'none',
                                        borderRadius: '2px',
                                        boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',
                                        width: '100%'
                                    }}
                                        height="1000"
                                        src="https://charts.mongodb.com/charts-tbtc-terrabot-prod-afrpv/embed/charts?id=5e5022fc-a3d6-4e20-b675-82f7bef69c52&theme=light">

                                    </iframe>
                                    <br />
                                    <iframe
                                        style={{
                                            background: '#FFFFFF',
                                            border: 'none',
                                            borderRadius: '2px',
                                            boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',
                                            width: '100%'
                                        }}
                                        height="1000" src="https://charts.mongodb.com/charts-tbtc-terrabot-prod-afrpv/embed/charts?id=8ec2730e-0583-48a0-a5ac-566b99519b7c&theme=light"></iframe>
                                </div>
                            </div>
                            :
                            <div></div>
                    }
                </div >
                :
                <div>

                </div>
        }
    </div >
}

export default Analytics;