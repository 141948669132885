import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Spinner
} from 'reactstrap';
import Switch from "react-switch";
import { WithOutContext as ReactTags } from 'react-tag-input-no-dnd';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { UserContext } from "../../../providers/UserProvider";
import { firestore } from '../../../firebase'
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import SelectSearch from 'react-select-search';
import 'assets/css/react-select-search.css'
import Fuse from 'fuse.js';
import ApiService from 'components/ApiService';
import NotificationAlert from 'react-notification-alert';

const KeyCodes = {
    comma: 188,
    enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const NewStandardStrategy = () => {
    const notifyRef = useRef(null);
    const user = useContext(UserContext);
    const apiService = new ApiService()

    const [account, setAccount] = useState(0)

    const [exchangeModes] = useState(['', 'Binance Future', 'Okex Margin'])
    const [selectedBotType, setBotType] = useState(exchangeModes[0])

    const [pairs, setPairs] = useState([])
    const [selectedPair, setPair] = useState(pairs[0])

    const [strategies, setStrategies] = useState(['SHORT', 'LONG'])
    const [selectedStrategy, setStrategy] = useState(strategies[0])

    const [geometricTypes] = useState(['Multiplier', 'Ultrafactor'])
    const [selectedGeometricType, setGeometricType] = useState(geometricTypes[0])

    const [orderBaseTypes] = useState(['Wallet %', 'Fixed amount'])
    const [selectedOrderBaseType, setOrderBaseType] = useState(orderBaseTypes[0])

    const [orderTypes] = useState(['Multiplier', 'Linear'])
    const [selectedOrderType, setOrderType] = useState(orderTypes[0])

    const [userStrategies, setUserStrategies] = useState([])


    const Exchange = exchangeModes.map(BotType => BotType)
    const Pair = pairs.map(pair => pair)
    const Strategy = strategies.map(strategy => strategy)
    const GeometricType = geometricTypes.map(geometricType => geometricType)
    const OrderBaseType = orderBaseTypes.map(orderBaseType => orderBaseType)
    const OrderType = orderTypes.map(orderType => orderType)

    const [currentGridOrders, setGridOrders] = useState(5)
    const [currentOrderStep, setOrderStep] = useState(0.5)
    const [currentGeometricFactor, setGeometricFactor] = useState(1)
    const [currentOrderSize, setOrderSize] = useState(0.1)
    const [currentOrderFactor, setOrderFactor] = useState(1)
    const [currentNote, setNote] = useState('')

    const [currentTakeStep, setTakeStep] = useState(1)
    const [currentTakeStepLimit, setTakeStepLimit] = useState(0)
    const [currentTakeStepLimitThreshold, setTakeStepLimitThreshold] = useState(0)

    const [currentTrailingStop, setTrailingStop] = useState(false)
    const [currentStopLoss, setStopLoss] = useState(false)

    const [currentCallbackRate, setCallbackRate] = useState(0)
    const [currentStopLossPercentage, setStopLossPercentage] = useState(0)

    const [tags, setTags] = useState([])
    const [suggestions, setSuggestions] = useState([])

    const [showSuccessModal, setSuccesModal] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleExchangeTypeChange = (e) => {
        setBotType(exchangeModes[e.target.value])
    }

    const handlePairChange = (e) => {
        console.log(e)
        setPair(e)
    }

    const handleStrategyChange = (e) => {
        setStrategy(strategies[e.target.value])
    }

    const handleGeometricTypeChange = (e) => {
        setGeometricType(geometricTypes[e.target.value])
    }

    const handleOrderBaseTypeChange = (e) => {
        setOrderBaseType(orderBaseTypes[e.target.value])
        if (orderBaseTypes[e.target.value] === 'Fixed amount') {
            setOrderSize(5)
        } else {
            setOrderSize(1)
        }
    }

    const handleOrderTypeChange = (e) => {
        setOrderType(orderTypes[e.target.value])
    }

    const handleAddition = (tag) => {
        setTags([...tags, tag])
    }

    const handleDelete = (i) => {
        setTags([...tags.filter((tag, index) => index !== i)])
    }

    const fuzzySearch = (options) => {
        const fuse = new Fuse(options, {
            keys: ['name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }

            return fuse.search(value);
        };
    }

    const fetchSymbols = useCallback(async () => {
        if (user) {
            let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
            let bots = localStorage.getItem('fullBots') ? JSON.parse(localStorage.getItem('fullBots')) : []
            const records = Buffer.from(storage.account, 'base64').toString()
            const bot = bots.filter(bot => bot.id === records)[0]
            if (bot) {
                const symbols = await apiService.getSymbols(
                    bot.exchangeType,
                    user
                )
                if (bot.exchangeType === 'okexm')
                    setStrategies([
                        'SHORT',
                        'LONG',
                        'BOTH'
                    ])
                const pairs = symbols.sort().map(pair => ({ name: pair, value: pair }))
                setPairs(pairs)
                firestore
                    .collection('strategies')
                    .where("botId", '==', bot.id)
                    .where('userId', '==', user.uid)
                    .onSnapshot((snapshot) => {
                        let tmpArray = []

                        if (snapshot.size === 0) {
                            tmpArray = []
                        } else {
                            snapshot.forEach((doc) => {
                                const newStrategy = { ...doc.data(), id: doc.id }
                                tmpArray.push(newStrategy)
                            });
                        }
                        setUserStrategies(tmpArray)
                    })
            }

        }
    }, [user])

    useEffect(() => {
        if (user === null) {
            console.log('nulling')
        } else {
            fetchSymbols()

            let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
            if (storage.account) {
                const account = Buffer.from(storage.account, 'base64').toString()
                setAccount(account)
                let fullBots = localStorage.getItem('fullBots') ? JSON.parse(localStorage.getItem('fullBots')) : []
                fullBots = fullBots.filter(b => b.id === account)[0]
                if (fullBots.exchangeType === 'okexm') {
                    setBotType('Okex Margin')
                } else if (fullBots.exchangeType === 'binancef') {
                    setBotType('Binance Future')
                }

            }
            if (storage.tags) {
                const cachedTags = []
                storage.tags.map((tag) => {
                    cachedTags.push({ id: tag, text: tag })
                })
                setSuggestions(cachedTags)
            } else {
                firestore
                    .doc(`users/${user.uid}`)
                    .get()
                    .then((data) => {
                        let obj = data.data()
                        let tags = []
                        obj.tags.forEach((doc) => {
                            tags.push({ id: doc, text: doc })
                        })
                        const onlyValues = tags.map(tag => tag.text)
                        setSuggestions(tags)
                        storage.tags = onlyValues
                        localStorage.setItem('preferences', JSON.stringify(storage))
                    })
            }
        }
    }, [user]);

    const notification = (obj) => {
        notifyRef.current.notificationAlert({
            place: 'bc',
            message: (
                <div>
                    <div>
                        <b>{obj.text}</b>
                    </div>
                </div>
            ),
            type: obj.type,
            autoDismiss: 2
        });
    }

    const findSameStrategy = (exchange, symbol, side) => {
        console.log(exchange, ' ', symbol, ' ', side)
        let passedCheck = true
        for (let i = 0; i < userStrategies.length; i++) {
            console.log(`${userStrategies[i].exchange}, ${exchange}`)
            console.log(`${userStrategies[i].symbol}, ${symbol}`)
            console.log(`${userStrategies[i].positionSide}, ${side}`)
            if (
                userStrategies[i].exchange === exchange &&
                userStrategies[i].symbol === symbol &&
                userStrategies[i].positionSide === side
            ) {
                passedCheck = false
            }
        }
        return passedCheck
    }

    const checkSameCoinStrategy = (symbol) => {
        let passedCheck = true
        for (let i = 0; i < userStrategies.length; i++) {
            console.log(`${userStrategies[i].symbol}, ${symbol}`)
            if (
                userStrategies[i].symbol === symbol &&
                userStrategies[i].exchange === 'okexm'
            ) {
                passedCheck = false
            }
        }
        return passedCheck
    }

    const creteNewBot = () => {
        setLoading(true)
        let stratTags = tags.map(tags => tags.text)

        if (selectedBotType === 'Binance Future')
            if (!findSameStrategy(
                selectedBotType === 'Binance Future' ? 'binancef' : 'okexm',
                selectedPair,
                selectedStrategy
            )) {
                setLoading(false)
                notification({
                    type: 'danger',
                    text: 'You cannot have different strategies with the same: Strategy (short/long), Pair and Exchange!'
                })
                return -1
            }
        if (selectedBotType === 'Okex Margin') {
            // check okex constraint
            if (!checkSameCoinStrategy(selectedPair)) {
                setLoading(false)
                notification({
                    type: 'danger',
                    text: 'You cannot have different strategies with the same pair'
                })
                return -1
            }
        }


        firestore.collection("strategies").add({
            botId: account,
            symbol: selectedPair,
            exchange: selectedBotType === 'Binance Future' ? 'binancef' : 'okexm',
            typeStrategy: selectedStrategy.toLowerCase(),
            typeGrid: "standard",
            gridOrders: currentGridOrders,
            gridStep: currentOrderStep,
            stepType: selectedGeometricType,
            stepFactor: currentGeometricFactor,
            orderBaseType: selectedOrderBaseType === "Wallet %" ? 'percentage' : 'fix',
            orderSize: currentOrderSize,
            orderType: selectedOrderType,
            orderFactor: currentOrderFactor,
            positionSide: selectedStrategy,

            takeStep: currentTakeStep,
            takeStepLimit: currentTakeStepLimit,
            takeStepLimitThreshold: currentTakeStepLimitThreshold,

            callBack: currentCallbackRate,
            stopLossOffset: currentStopLoss === true ? currentStopLossPercentage : 0,

            status: "",
            tags: stratTags,

            apiPublic: false,

            note: currentNote,

            userId: user.uid
        })
            .then(async () => {
                const userRef = firestore.collection('users').doc(user.uid);
                const userDetail = await userRef.get()
                const oldTags = userDetail.data().tags
                console.log('old tags', oldTags)

                const uniq = [...new Set(stratTags.concat(oldTags))];

                let storage = localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {}
                storage.tags = uniq
                localStorage.setItem('preferences', JSON.stringify(storage));

                firestore.collection("users").doc(user.uid).set({
                    tags: uniq
                }, {
                    merge: true
                })
                setTags([])
                setLoading(false)
                setSuccesModal(true)
            })
            .catch((error) => {
                setLoading(false)
                console.error("Error writing document: ", error);
            });
    }

    return <div className="content" style={{ marginTop: '20px' }}>

        {
            account !== 0 ?

                <div>
                    <h4 className="pt-0 mt-1">GENERAL SECTION</h4>
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <div className="form-group">
                                <label>Exchange</label>
                                <input type="text" className="form-control" disabled value={selectedBotType}></input>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="form-group">
                                <label>Select Pair</label>
                                <SelectSearch
                                    options={pairs}
                                    onChange={(e) => handlePairChange(e)}
                                    search
                                    filterOptions={fuzzySearch}
                                    name="Pair"
                                    placeholder="Choose your pair" />
                                {/*<select
                                    className="form-control"
                                    onChange={e => handlePairChange(e)}
                                >
                                    {
                                        Pair.map((type, key) => <option key={key} value={key}>{type}</option>)
                                    }
                                </select>*/}
                            </div>
                        </div>
                        <div className="col-md-3 col-12 col-sm-6">
                            <div className="form-group">
                                <label>Strategy</label>
                                <select
                                    className="form-control"
                                    onChange={e => handleStrategyChange(e)}
                                >
                                    {
                                        Strategy.map((type, key) => <option key={key} value={key}>{type}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <h4 className="pt-0 mt-1">GRID SECTION</h4>
                    <hr className="w-75 ml-0" />
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Grid Orders</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={currentGridOrders}
                                    min={1}
                                    onChange={(event) => setGridOrders(parseInt(event.target.value))}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>1° Order Step (%)</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    value={currentOrderStep}
                                    min={0}
                                    onChange={(event) => setOrderStep(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="form-group">
                                <label>Step Type</label>
                                <select
                                    className="form-control"
                                    onChange={e => handleGeometricTypeChange(e)}
                                    value={geometricTypes[0]}
                                    disabled={true}
                                >
                                    {
                                        GeometricType.map((type, key) => <option key={key} value={key}>{type}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="form-group">
                                <label>Step Factor</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={currentGeometricFactor}
                                    step={0.01}
                                    min={0}
                                    onChange={(event) => setGeometricFactor(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>
                    </div>

                    <h4 className="pt-0 mt-1">ORDER SECTION</h4>
                    <hr className="w-75 ml-0" />
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Order Base Type</label>
                                <select
                                    className="form-control"
                                    onChange={e => handleOrderBaseTypeChange(e)}
                                >
                                    {
                                        OrderBaseType.map((type, key) => <option key={key} value={key}>{type}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Order Size {selectedOrderBaseType === 'Fixed amount' ? '' : '(%)'}</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    value={currentOrderSize}
                                    step={0.1}
                                    min={selectedOrderBaseType === 'Wallet %' ? 0.1 : 5}
                                    onChange={(event) => {
                                        const value = parseFloat(event.target.value.replace(',', '.'))
                                        setOrderSize(value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Order Type</label>
                                <select
                                    className="form-control"
                                    onChange={e => handleOrderTypeChange(e)}
                                    value={orderTypes[0]}
                                    disabled={true}
                                >
                                    {
                                        OrderType.map((type, key) => <option key={key} value={key}>{type}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Order Factor</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={currentOrderFactor}
                                    onChange={(event) => setOrderFactor(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Take Step (%)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={currentTakeStep}
                                    min={0}
                                    onChange={(event) => setTakeStep(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Take step limit (%)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={currentTakeStepLimit}
                                    min={0}
                                    step={0.01}
                                    onChange={(event) => setTakeStepLimit(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">

                            <div className="form-group">
                                <label>Stop loss</label><br />
                                <Switch
                                    onChange={e => setStopLoss(!currentStopLoss)}
                                    checked={currentStopLoss}
                                    className="react-switch"
                                    onChange={e => {
                                        console.log(e)
                                        setStopLoss(e)
                                        if (e)
                                            setStopLossPercentage(0.1)
                                        else
                                            setStopLossPercentage(0)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Stop loss (%)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={currentStopLossPercentage}
                                    min={0.1}
                                    step={0.01}
                                    disabled={!currentStopLoss}
                                    onChange={(event) => setStopLossPercentage(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>
                        {/*
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Take Step Limit Threshold</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    step={1}
                                    min={0}
                                    value={currentTakeStepLimitThreshold}
                                    onChange={(event) => setTakeStepLimitThreshold(parseInt(event.target.value))}
                                />
                            </div>
                        </div>
                        */}
                    </div>
                    <NotificationAlert ref={notifyRef} zIndex={9999} onClick={() => console.log("hey")} />
                    <div className="row">
                        {/*
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Trailing Stop</label><br />
                                <Switch
                                    onChange={e => {
                                        console.log(e)
                                        setTrailingStop(e)
                                        if (e)
                                            setCallbackRate(0.1)
                                        else
                                            setCallbackRate(0)
                                    }}
                                    checked={currentTrailingStop}
                                    className="react-switch"
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Callback rate (%)</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    disabled={!currentTrailingStop}
                                    value={currentCallbackRate}
                                    min={0.1}
                                    step={0.01}
                                    onChange={(event) => setCallbackRate(parseFloat(event.target.value))}
                                />
                            </div>
                        </div>
                        */}

                    </div>

                    <div className="row">
                        <div className="form-group col-12">
                            <div className="form-group">
                                <h4 className="mt-2 mb-2">Note</h4>
                                <p>Add a free description to this strategy!</p>
                                <textarea rows="1" className="form-control" value={currentNote} onChange={(e) => setNote(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-12">
                            <div className="form-group">
                                <h4 className="mt-2 mb-2">STRATEGY TAGS</h4>
                                <p>You can filter your strategies with them!</p>
                                <ReactTags tags={tags}
                                    suggestions={suggestions}
                                    handleAddition={handleAddition}
                                    handleDelete={handleDelete}
                                    inputFieldPosition="inline"
                                    allowDeleteFromEmptyInput={false}
                                    placeholder="Type something.."
                                    delimiters={delimiters}
                                    minQueryLength={1}
                                    classNames={{
                                        tagInputField: "rounded p-1"
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-4 col-12 mx-auto">
                            <Button
                                className="btn btn-success btn-block"
                                size="bg"
                                onClick={() => creteNewBot()}
                            >
                                {!loading ? 'Create new strategy' : <Spinner size="sm" color="primary" />}
                            </Button>
                        </div>
                    </div>
                    <SweetAlert
                        show={showSuccessModal}
                        title="New strategy created!"
                        type="success"
                        showCancel={true}
                        allowEscape={true}
                        closeOnClickOutside={true}
                        onConfirm={() => window.location.href = '/user/home'}
                        onCancel={() => {
                            setTags([])
                            setSuccesModal(false)
                        }}
                        cancelBtnText="Create another one"
                        confirmBtnText="Go to your strategies"
                    />
                </div>
                :
                <div className="row text-center mt-4 pt-4">
                    <div className="col-6 col-xs-12 mx-auto">
                        <h3>Choose your account with "Switch account" or <a href="/user/new-bot">creating a new one</a>!</h3>
                    </div>
                </div>

        }

    </div>
}

export default NewStandardStrategy;